import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ActionButton } from '..';
import useRoutes from "../../../store/routes";
import useModal from "../../../store/modal";
import { assignDeliveryApi } from '../../../api/routes';
import { toast } from 'react-toastify';
import useAuth from 'hooks/useAuth';

export default function Assign() {
    const fetchDeliveries = useRoutes((state) => state.fetchDeliveries);
    const fetchRoutes = useRoutes((state) => state.fetchRoutes);
    const deliveries = useRoutes((state) => state.deliveries);
    const props = useModal((state) => state.props);
    const closeModal = useModal((state) => state.closeModal);
    const [selectedDelivery, setSelectedDelivery] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredDeliveries, setFilteredDeliveries] = useState(deliveries);
    const { user } = useAuth();

    useEffect(() => {
        fetchDeliveries(user);
    }, []);

    const options = deliveries.map(delivery => ({
        value: delivery._id,
        label: `${delivery.name} ${delivery.lastName}`
    }));

    const handleInputChange = (inputValue) => {
        setSearchTerm(inputValue);
    };

    const handleChange = (selectedOption) => {
        setSelectedDelivery(selectedOption.value);
        setSearchTerm(selectedOption.label);
    };

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        fetchDeliveries(user);
    }, []);

    return (
        <>
            <div className="text-sm">
                Estás a punto de asignar la ruta a un repartidor. Puedes eliminarla o reasignarla en cualquier momento.
            </div>
            
            <Select 
                options={filteredOptions}
                onInputChange={handleInputChange}
                onChange={handleChange}
                placeholder="Buscar repartidor..."
                value={options.find(option => option.value === selectedDelivery)}
            />
            <ActionButton
                text={"Asignar"}
                primary="#FEF3D4"
                secondary="#FFC107"
                onClick={() => {
                    toast.promise(assignDeliveryApi(props.id, selectedDelivery), {
                        pending: "Asignando...",
                        success: "Ruta asignada correctamente",
                        error: "Error al asignar la ruta",
                    }).then(() => {
                        fetchRoutes().then(() => {
                            closeModal();
                        });
                    });
                }}
            />
        </>
    );
}

