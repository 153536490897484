// src/api/inform.js

import Axios from "../config/axios-config";
import { downloadExcel } from "../utils/downloadExcel";
import { saveAs } from "file-saver";

// Función para aplanar objetos anidados (opcional)
const flattenObject = (obj, parent = '', res = {}) => {
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;
    const propName = parent ? `${parent}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

export const generateInformApi = async (values, type) => {
  try {
    const response = await Axios.post("reports/packages-report", values, { timeout: 0 });
    
    //console.log('Respuesta del API:', response.data); // Verifica la respuesta

    const filename =
      type === "all"
        ? "Informe Consolidado"
        : type === "client"
        ? "Informe por Cliente"
        : "Informe por Repartidor";

    // Procesar los datos
    let processedData = response.data.result.map(item => {
      // Crear un nuevo objeto sin las claves numéricas
      const cleanItem = {};
      for (const key in item) {
        if (!/^\d+$/.test(key)) { // Excluir claves que son solo números
          cleanItem[key] = item[key];
        }
      }

      // Opcional: Aplanar objetos anidados
      // const flatItem = flattenObject(cleanItem);

      // Retornar el objeto limpio (o aplanado si se usa flattenObject)
      return cleanItem; // O return flatItem;
    });

    //console.log('Datos procesados para Excel:', processedData); // Verifica los datos procesados

    if (processedData.length > 0) {
      downloadExcel({ dataToExcel: processedData, fileName: filename });
    } else {
      console.warn('No hay datos para generar el Excel.');
      // Opcional: Mostrar una notificación al usuario
    }
  } catch (err) {
    console.error('Error al generar el informe:', err);
    const errorResponse = err.response ? err.response.data : { message: err.message };
    return { error: errorResponse };
  }
};

export const generateCollectReportApi = async (values) => {
  try {
    const response = await Axios.post("reports/collect",values,{responseType: "blob", timeout: 0,});

    const filename = "Informe_de_Colecta.xlsx"; // Nombre del archivo

    // Descargar el archivo Excel
    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    saveAs(blob, filename);
  } catch (err) {
    console.error("Error al generar el informe Collect:", err);
    const errorResponse = err.response ? err.response.data : { message: err.message };
    return { error: errorResponse };
  }
};
