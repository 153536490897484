import { AuthService } from "services/authservice";
import Axios from "../config/axios-v1-config";


export const getAllPackages = async ({
  id,
  page,
  limit,
  query,
  orderField,
  coms,
  status,
  startDate,
  endDate,
}) => {
  try {
    const params = new URLSearchParams({
      page,
      limit,
      orderField,
      sortType: 'desc',
      ...(query && { [query.type]: query.query }),
      ...(status && { status }),
      ...(coms && { 'shipping_address.city.name': coms }),
      ...(startDate && { startDateCreation: startDate, endDateCreation: endDate }),
    });

    //console.log("Calling API with params:", params.toString()); // Log the params

    const response = await Axios.get(
      `packages?${params.toString()}`
    );
    //console.log("Response from API:", response.data);

    return response.data;
  } catch (err) {
    console.error("Error in API call:", err.response?.data || 'Unknown error');
    return { error: err.response?.data || 'Unknown error' };
  }
};



// Dvlprchris
export const getOnePackage = async (id) => {
  const packageFound = await Axios.get(`packages/${id}`).catch((err) => {
    return { error: err.response.data };
  });


  return packageFound.data

}

export const countAllPackages = async ({
  id,
  page,
  limit,
  query,
  orderField,
  coms,
  status,
  startDate,
  endDate,
}) => {
  const packages = await Axios.get(
    `countPackages/${id}?&page=${page}&limit=${limit}${query && `&${query.type}=${query.query}`}&orderField=${orderField}&sortType=desc${status ? `&status=${status}` : ""
    }${coms ? `&shipping_address.city.name=${coms}` : ""}${startDate
      ? `&startDateCreation=${startDate}&endDateCreation=${endDate}`
      : ""
    }`
  ).catch((err) => {
    return { error: err.response.data };
  });

  return packages;
}

export const getAllPackagesByStatus = async ({
  id,
  state,
  startDate,
  endDate,
}) => {
  const packages = await Axios.get(
    `status-packages/${id}?state=${state}&startDate=${startDate}&endDate=${endDate}`
  ).catch((err) => {
    return { error: err.response.data };
  });

  return packages;
};

export const updatePackageStatus = async ({ status, id }) => {
  const packageUpdated = await Axios.put(`packages/update/${id}`, {
    value: { status },
  }).catch((err) => {
    return { error: err.response.data };
  });

  return packageUpdated;
};

export const createPackage = async ({ values }) => {
  //console.log("values", values);
  const packageCreated = await Axios.post(`packages`, values).catch((err) => {
    return { error: err.response.data };
  });

  return packageCreated.data
};

export const loadFile = async ({ file, user }) => {
  const documentLoaded = await Axios.post(`base/add-excel`, {
    bdata: file,
    user,
  }).catch((err) => {
    return { error: err.response.data };
  });

  return documentLoaded;
};

export const loadCustomFile = async ({ file, user }) => {
  const documentLoaded = await Axios.post(`add-custom-excel`, {
    bdata: file,
    user,
  }).catch((err) => {
    return { error: err.response.data };
  });

  return documentLoaded;
};

export const getAdminPackages = async ({
  page,
  limit,
  query,
  orderField,
  sortType,
  coms,
  status,
  startDate,
  endDate,
  assignTo,
  client,
}) => {

  const params = new URLSearchParams();
  
  params.append("page", page);
  params.append("limit", limit);
  params.append("orderField", orderField);
  params.append("sortType", sortType || "desc");

  if (client) params.append("senderInfo.companyId", client);
  if (assignTo) params.append("assignTo", assignTo);
  if (query && query.type && query.query) params.append(query.type, query.query);
  if (status) params.append("status", status);
  if (coms) params.append("shipping_address.city.name", coms);
  if (startDate && endDate) {
    params.append("startDateCreation", startDate);
    params.append("endDateCreation", endDate);
  }

  const packages = await Axios.get(`packages?${params.toString()}`)
    .catch((err) => {
      return { error: err.response?.data };
    });

  return packages;
};

export const getAllPackagesByStatusForAdmin = async ({
  state,
  startDate,
  endDate,
}) => {
  const packages = await Axios.get(
    `admin-status-packages?state=${state}&startDate=${startDate}&endDate=${endDate}`
  ).catch((err) => {
    return { error: err.response.data };
  });

  return packages;
};

export const updatePackage = async ({ id, values }) => {
  const updatedPackage = await Axios.put(`packages/update/${id}`, {
    value: values,
  }).catch((err) => {
    return { error: err.response.data };
  });

  return updatedPackage;
};
