import jwtDecode from "jwt-decode";
import { ACCESS_TOKEN, REFRESH_TOKEN, basePath, apiVersion } from "../config/constants";

const getAccessTokenApi = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (!accessToken || accessToken === "null") {
    return null;
  }

  return willExpireToken(accessToken) ? null : accessToken;
};

const getRefreshTokenApi = () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);

  if (!refreshToken || refreshToken === "null") {
    return null;
  }

  return willExpireToken(refreshToken) ? null : refreshToken;
};

const refreshAccessToken = (refreshToken) => {
  const url = `${basePath}/auth/refresh`;
  const bodyObj = {
    refreshToken: refreshToken,
  };
  const params = {
    method: "POST",
    body: JSON.stringify(bodyObj),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, params)
    .then((response) => {
      if (response.status !== 200) {
        return null;
      }
      return response.json();
    })
    .then((result) => {
      if (!result) {
        logout();
        return null;
      } else {
        const { accessToken, refreshToken } = result;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        return accessToken;
      }
    });
};

const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

function willExpireToken(token, bufferTimeInSeconds = 300) {
  const { exp } = jwtDecode(token);
  const now = Math.floor(Date.now() / 1000);
  console.log("Current Time (seconds):", now);
  console.log("Token Expiration Time (seconds):", exp);
  return now > exp - bufferTimeInSeconds;
}

export const AuthService = {
  getAccessTokenApi,
  getRefreshTokenApi,
  refreshAccessToken,
  logout,
};
