import React from "react";
import { iconList } from "../../utils/iconList";
import moment from "moment/moment";
import "moment/locale/es";

const parseDate = (date) => {
  if (date && (typeof date === "string" || date.updatedAt)) {
    const formattedDate = typeof date === "string" ? date : date.updatedAt;
    return moment(formattedDate).format("DD MMM YYYY - HH:mm");
  }
  return "Fecha no disponible"; // Provide a fallback message if no valid date
};

export default function Stepper({ packageData }) {
  if (!packageData) {
    return (
      <div className="error-message">
        <p>Error: No se recibió información del paquete.</p>
      </div>
    );
  }

  const steps = [
    { statusCode: 0, label: "Creado" },
    { statusCode: 1, label: "Recolectado" },
    { statusCode: 2, label: "En Bodega" },
    { statusCode: 3, label: "En Ruta" },
    { statusCode: 4, label: "Entregado" },
    { statusCode: 5, label: "Cancelado por el Vendedor" },
    { statusCode: 6, label: "Dirección Incorrecta o Incompleta" },
    { statusCode: 7, label: "Sin Moradores" },
  ];

  // Create a mapping of status codes to their corresponding entries
  let statusMap = {};
  if (
    packageData?.packageStatusHistory &&
    Array.isArray(packageData.packageStatusHistory)
  ) {
    packageData.packageStatusHistory.forEach((statusEntry) => {
      statusMap[statusEntry.status] = statusEntry;
    });
  } else {
    return (
      <div className="error-message">
        <p>Error: El historial de estados del paquete no está disponible.</p>
      </div>
    );
  }

  const currentStatus = packageData?.status ?? -1;
  const isTerminalStatus = (statusCode) => [4, 5, 6, 7].includes(statusCode);

  return (
    <div className="d-grid align-items-center">
      {steps.map((step, index) => {
        const statusEntry = statusMap[step.statusCode];

        let isCurrentStep = false;
        let isCompletedStep = false;
        let isPendingStep = false;

        if (isTerminalStatus(currentStatus)) {
          if (step.statusCode === currentStatus) {
            isCurrentStep = true;
          } else if (
            steps.findIndex((s) => s.statusCode === currentStatus) > index
          ) {
            isCompletedStep = true;
          } else {
            isPendingStep = true;
          }
        } else {
          if (step.statusCode === currentStatus) {
            isCurrentStep = true;
          } else if (step.statusCode < currentStatus) {
            isCompletedStep = true;
          } else {
            isPendingStep = true;
          }
        }

        if (isTerminalStatus(currentStatus) && step.statusCode > currentStatus) {
          return null;
        }

        return (
          <React.Fragment key={step.statusCode}>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div
                  className={`rounded-circle ${
                    isCurrentStep
                      ? "vertical-process-rounded"
                      : isCompletedStep
                      ? "vertical-complete-rounded"
                      : "vertical-pending-rounded"
                  } d-flex justify-content-center align-items-center`}
                >
                  {isCompletedStep && (
                    <img src={iconList.check} alt="Completado" />
                  )}
                  {isCurrentStep && (
                    <div className="rounded-circle vertical-on-course vertical-rounded-process" />
                  )}
                  {isPendingStep && (
                    <div className="rounded-circle vertical-rounded-pending-process" />
                  )}
                </div>
                <div className="d-grid ml-3">
                  <span className="vertical-custom-status-following">
                    {step.label}
                  </span>
                </div>
              </div>
              <div className="date-container">
                <span className="date-size">
                  {statusEntry ? parseDate(statusEntry.updatedAt) : "N/A"}
                </span>
              </div>
            </div>
            {index < steps.length - 1 &&
              !(isTerminalStatus(currentStatus) && step.statusCode >= currentStatus) && (
                <div className="d-flex justify-content-center line-container">
                  <div
                    className={`${
                      isCompletedStep || isCurrentStep
                        ? "vertical-line-flow"
                        : "vertical-line-flow-pending"
                    }`}
                  />
                </div>
              )}
          </React.Fragment>
        );
      })}

      {/* Render error message if no steps are displayed */}
      {steps.length === 0 && (
        <div className="error-message">
          <p>Todavía no hay eventos</p>
        </div>
      )}
    </div>
  );
}
