import React, { useCallback, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Container,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { createPackage } from "../../api/packages";
import { getUser } from "../../api/user";
import { packageStatus } from "../../utils/packageStatus";
import { posibleComs } from "../../utils/coms";
import { showToast } from "../../utils/showToast";
import SearchDropdown from "../searchDropdown";
import useAuth from "../../hooks/useAuth";
import FormDivider from "../formsDivider";
import Stepper from "../stepper";
import ErrorMessage from "../errorMessage";
import { vehiclesTypes } from "../../data/index";
import { createDeliveryUser, updateDeliveryUser  } from "../../api/user";
import Modal from "../../../src/components/modal";
import { toast } from 'react-toastify';

export default function CreateDeliveryBeartrack({
  edit,
  defaultData,
  closeModal,
  cerrarModal,
  fetch,
  setConfirmationModal,
  setPackagesCreated,
  user,
}) {
  //console.log("datos del delivery para editar: ", user);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [step, setStep] = useState(1);

  const titles = {
    form1: "Datos personales",
    form2: "Datos para pagos",
  };
  //logica steps
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };
  // end -logica steps

  const methods = useForm({
    mode: "onSubmit",
    defaultValues: edit ? user : {},
  });

  useEffect(() => {
    if (edit) {
      //console.log("en el useEffect");
    }
  }, [edit, user, methods]);

  const ErrorMessage = ({ msg }) => (
    <FormFeedback className="d-block">{msg}</FormFeedback>
  );

  const FormDivider = ({ className }) => (
    <div className={`w-full border-t border-gray-300 my-2 ${className}`} />
  );
  
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (e, field) => {
    const file = e.target.files[0];
    if (file && file.size <= 5242880) {
      const base64 = await convertFileToBase64(file);
      field.onChange(base64);
    } else {
      methods.setError(field.name, {
        type: "manual",
        message: "El archivo debe ser PDF, PNG o JPG y menor a 5 MB",
      });
    }
  };

  const submitValues = useCallback(
    async (values) => {
      setLoading(true);
      const toastId = toast.loading("Guardando cambios...");
      const accessToken = localStorage.getItem('accessToken');
    
      if (accessToken) {
        //console.log("datos accessToken::::", accessToken);
        values.token = accessToken;
      }

      try {
        if (edit) {
          await updateDeliveryUser(values.id, values);
          toast.update(toastId, {
            appearance: "success",
            render: "Usuario actualizado exitosamente",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            position: "bottom-center",
            autoDismiss: true,
          });
        } else {
          await createDeliveryUser(values);
          toast.update(toastId, {
            appearance: "success",
            render: "Usuario creado exitosamente",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            position: "bottom-center",
            autoDismiss: true,
          });
        }
        setLoading(false);
        closeModal(false);
        fetch(true); //comentado para pruebas
      } catch (error) {
        // console.error("Error al guardar los cambios:", error);
        setLoading(false);
        toast.update(toastId, {
          appearance: "error",
          render: "Error al guardar los cambios: " + error.message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
          position: "bottom-center",
          autoDismiss: true,
        });
      }
    },
    [closeModal, edit, fetch, user]
  );

  const getCurrentTitle = () => {
    switch (step) {
      case 1:
        return titles.form1;
      case 2:
        return titles.form2;
      default:
        return titles.form1;
    }
  };

  const isForm1Complete =
    methods.watch("name") &&
    methods.watch("email") &&
    methods.watch("phone")
    // methods.watch("carDocument") &&
    // methods.watch("carId")
    ;

  const isForm2Complete =
    methods.watch("namePayPerson") &&
    methods.watch("rutPayPerson") &&
    methods.watch("accountType") &&
    methods.watch("bank");

  return (
    <div>
      <h1 className="text-black font-semibold text-xl mb-0 mt-0">
        {getCurrentTitle()}
      </h1>
      <FormDivider />
      <Container className="mt-0">
        <Form onSubmit={methods.handleSubmit(submitValues)}>
          {step === 1 && (
            <>
              <Row className="mt-0">
                <Col xs="12" className="">
                <Label className="forms-input-title">Nombre</Label>
                  <Controller
                    name="name"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        className="p-2 px-3 forms-custom-input"
                        placeholder="Pedro Gonzalez"
                        defaultValue=""
                      />
                    )}
                    rules={{
                      required: "Ingrese el Nombre del vendedor",
                    }}
                  />
                  {methods.formState.errors?.name && (
                    <ErrorMessage
                      msg={methods.formState.errors?.name?.message}
                    />
                  )}
                </Col>
                <Col xs="12" className="">
                <Label className="forms-input-title">Correo electrónico</Label>
                  <Controller
                    name="email"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="email"
                        className="p-2 px-3 forms-custom-input"
                        placeholder="pedro@otsl.cl"
                        defaultValue=""
                      />
                    )}
                    rules={{
                      required: "Ingrese el Correo electrónico del vendedor",
                    }}
                  />
                  {methods.formState.errors?.email && (
                    <ErrorMessage
                      msg={methods.formState.errors?.email?.message}
                    />
                  )}
                </Col>
                <Col xs="12">
                <Label className="forms-input-title">Teléfono</Label>
                  <Controller
                    name="phone"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        className="p-2 px-3 forms-custom-input"
                        placeholder="+56987654321"
                        defaultValue=""
                      />
                    )}
                    rules={{
                      required: "Ingrese el Teléfono del vendedor",
                    }}
                  />
                  {methods.formState.errors?.phone && (
                    <ErrorMessage
                      msg={methods.formState.errors?.phone?.message}
                    />
                  )}
                </Col>
                <h1 className="text-black font-semibold text-xl mb-0 mt-4">
                  Documentos del vehículo
                </h1>
                <FormDivider></FormDivider>
                <Col xs="12">
                  <Label className="forms-input-title">
                    Licencia de conducir
                  </Label>
                  <Controller
                    name="driveLicense"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="file"
                        className="p-2 px-3 forms-custom-input"
                        accept=".pdf,.png,.jpg"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file && file.size <= 5242880) {
                            field.onChange(e);
                          } else {
                            methods.setError("driveLicense", {
                              type: "manual",
                              message:
                                "El archivo debe ser PDF, PNG o JPG y menor a 5 MB",
                            });
                          }
                        }}
                      />
                    )}
                    rules={{
                      required: "Por favor suba la licencia de conducir",
                    }}
                  />
                  {methods.formState.errors?.driveLicense && (
                    <ErrorMessage
                      msg={methods.formState.errors?.driveLicense?.message}
                    />
                  )}
                </Col>
                <Col xs="12">
                  <Label className="forms-input-title">
                    Permiso de circulación
                  </Label>
                  <Controller
                    name="carDocument"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="file"
                        className="p-2 px-3 forms-custom-input"
                        accept=".pdf,.png,.jpg"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file && file.size <= 5242880) {
                            field.onChange(e);
                          } else {
                            methods.setError("carDocument", {
                              type: "manual",
                              message:
                                "El archivo debe ser PDF, PNG o JPG y menor a 5 MB",
                            });
                          }
                        }}
                      />
                    )}
                    rules={{
                      required: "Por favor suba el permiso de circulación",
                    }}
                  />
                  {methods.formState.errors?.carDocument && (
                    <ErrorMessage
                      msg={methods.formState.errors?.carDocument?.message}
                    />
                  )}
                </Col>
                <Col xs="12">
                  <Label className="forms-input-title">
                    Padrón del vehículo
                  </Label>
                  <Controller
                    name="carId"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="file"
                        className="p-2 px-3 forms-custom-input"
                        accept=".pdf,.png,.jpg"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file && file.size <= 5242880) {
                            field.onChange(e);
                          } else {
                            methods.setError("carId", {
                              type: "manual",
                              message:
                                "El archivo debe ser PDF, PNG o JPG y menor a 5 MB",
                            });
                          }
                        }}
                      />
                    )}
                    rules={{
                      required: "Por favor suba el padrón del vehículo",
                    }}
                  />
                  {methods.formState.errors?.carId && (
                    <ErrorMessage
                      msg={methods.formState.errors?.carId?.message}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12">
                  <p className="text-sm">
                    <strong>IMPORTANTE:</strong>La contraseña se genera
                    automáticamente y <br /> es enviada al correo una vez
                    finalizada la creación de usuario.
                  </p>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs="6">
                  <Button
                    color="orange"
                    className={`w-100 bg-[#F8612C] text-white hover:bg-orange-500 ${
                      !isForm1Complete ? "disabled-button" : ""
                    }`}
                    type="button"
                    onClick={handleNextStep}
                    disabled={!isForm1Complete}
                  >
                    Siguiente paso
                  </Button>
                </Col>
                <Col xs="6">
                  <Button
                    color="white"
                    className="w-100 text-black border-black"
                    type="button"
                    onClick={() => {closeModal(false); cerrarModal(false)}}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </>
          )}

          {step === 2 && (
            <>
              <Row className="mt-0">
                <Col xs="12">
                  <Label className="forms-input-title">Nombre</Label>
                  <Controller
                    name="namePayPerson"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        className="p-2 px-3 forms-custom-input"
                        placeholder="Juan Pérez"
                        defaultValue=""
                      />
                    )}
                    rules={{ required: "Ingrese el nombre del repartidor" }}
                  />
                  {methods.formState.errors?.namePayPerson && (
                    <ErrorMessage
                      msg={methods.formState.errors?.namePayPerson?.message}
                    />
                  )}
                </Col>
                <Col xs="12">
                  <Label className="forms-input-title">RUT</Label>
                  <Controller
                    name="rutPayPerson"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        className="p-2 px-3 forms-custom-input"
                        placeholder="12.345.678-9"
                        defaultValue=""
                      />
                    )}
                    rules={{
                      required: "Ingrese el Correo electrónico del repartidor",
                    }}
                  />
                  {methods.formState.errors?.rutPayPerson && (
                    <ErrorMessage
                      msg={methods.formState.errors?.rutPayPerson?.message}
                    />
                  )}
                </Col>
                <Col xs="12">
                  <Label className="forms-input-title">Tipo de cuenta</Label>
                  <Controller
                    name="accountType"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="select"
                        className="p-2 px-3 forms-custom-input"
                        defaultValue=""
                      >
                        <option value="">Seleccione el tipo de cuenta</option>
                        <option value="corriente">Cuenta Corriente</option>
                        <option value="vista">Cuenta Vista</option>
                        <option value="chequera">Chequera electrónica</option>
                        <option value="ahorro">Cuenta de Ahorro</option>
                      </Input>
                    )}
                    rules={{ required: "Seleccione el tipo de cuenta" }}
                  />
                  {methods.formState.errors?.accountType && (
                    <ErrorMessage
                      msg={methods.formState.errors?.accountType?.message}
                    />
                  )}
                </Col>
                <Col xs="12">
                  <Label className="forms-input-title">Banco</Label>
                  <Controller
                    name="bank"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="select"
                        className="p-2 px-3 forms-custom-input"
                        defaultValue=""
                      >
                        <option value="">Seleccione el banco</option>
                        <option value="banco_chile">Banco de Chile</option>
                        <option value="banco_estado">BancoEstado</option>
                        <option value="bci">BCI</option>
                        <option value="santander">Santander</option>
                        <option value="scotiabank">Scotiabank</option>
                      </Input>
                    )}
                    rules={{ required: "Seleccione el banco" }}
                  />
                  {methods.formState.errors?.bank && (
                    <ErrorMessage
                      msg={methods.formState.errors?.bank?.message}
                    />
                  )}
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs="6">
                  <Button
                    color="orange"
                    className={`w-100 bg-[#F8612C] text-white hover:bg-orange-500 ${
                      !isForm2Complete ? "disabled-button" : ""
                    }`}
                    type="submit"
                    disabled={!isForm2Complete}
                  >
                    {edit ? "Editar usuario" : "Crear usuario"}
                  </Button>
                </Col>
                <Col xs="6">
                  <Button
                    color="white"
                    className="w-100 text-black border-black"
                    type="button"
                    onClick={() => {closeModal(false); cerrarModal(false)}}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Container>
    </div>
  );
}
