import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Label,
  Input,
  Col,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../components/searchBar";
import { iconList } from "../../../utils/iconList";
import useAuth from "../../../hooks/useAuth";
import { getUser, updateUserApi } from "../../../api/user";
import InputEdit from "../../../components/inputEdit";
import { showToast } from "../../../utils/showToast";

export default function MyAccount() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [userData, setUserData] = useState();
  const [newPassword, setNewPassword] = useState();

  const updatePassword = () => {
    if (!newPassword) showToast("La clave no se puede encontrar vacía", false);
    if (newPassword) {
      updateUserApi({
        userId: userData?._id,
        values: { password: newPassword },
      })
        .then(() => {
          showToast("Contraseña actualizada correctamente.", true);
          return true;
        })
        .catch((ex) => console.error(ex));
    }
    return true
  };

  useEffect(() => {
    getUser({ id: user?.id })
      .then((data) => setUserData(data?.data?.user))
      .catch((ex) => console.error(ex));
  }, [user]);

  return (
    <div className="w-100 all-users-container">
      <SearchBar />
      <Breadcrumb className="custom-breadcrumb-container">
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate("/home")}
        >
          <img src={iconList.home} alt="home" className="mr-2" />
          <span>Inicio</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Mi cuenta</span>
        </BreadcrumbItem>
      </Breadcrumb>
      <h2 className="h2-custom-size all-orders-container__title d-flex justify-content-between">
        Mi cuenta
      </h2>
      <Card className="custom-my-account-container mb-3">
        <CardBody className="m-0 p-0">
          <div className="custom-subtitle-my-account">DATOS CUENTA</div>
          <Label className="forms-input-title mt-4">NOMBRE</Label>
          <Input disabled value={userData?.name} />
          <Label className="forms-input-title mt-3">CORREO ELECTRÓNICO</Label>
          <Input disabled value={userData?.email} />
          <Label className="forms-input-title mt-3">CONTRASEÑA</Label>
          <InputEdit
            type={"password"}
            placeholder="Contraseña"
            onChange={(e) => setNewPassword(e.target.value)}
            onSend={updatePassword}
          />
          <Label className="custom-forms-subtitle mb-0 mt-4">
            DATOS EMPRESA
          </Label>
          <br />
          <Label className="forms-input-title mt-4">RAZÓN SOCIAL</Label>
          <Input disabled value={userData?.legalName} />
          <Label className="forms-input-title mt-3">RUT EMPRESA</Label>
          <Input disabled value={userData?.businessRut} />
          <Row>
            <Col xs="6" md="6" xl="6">
              <Label className="forms-input-title mt-3">DIRECCIÓN FISCAL</Label>
              <Input disabled value={userData?.fiscalAddress} />
            </Col>
            <Col xs="6" md="6" xl="6">
              <Label className="forms-input-title mt-3">COMUNA</Label>
              <Input disabled value={userData?.municipality} />
            </Col>
          </Row>
          <Label className="forms-input-title mt-3">
            RESPONSABLE COMERCIAL
          </Label>
          <Input disabled value={`${userData?.name} ${userData?.lastName}`} />
          <Label className="forms-input-title mt-3">
            RESPONSABLE OPERACIÓN
          </Label>
          <Input disabled value={userData?.nameOperationPerson} />
          <Label className="forms-input-title mt-3">RESPONSABLE PAGOS</Label>
          <Input disabled value={userData?.namePayPerson} />
        </CardBody>
      </Card>
    </div>
  );
}
