import React, { createContext, useEffect, useState } from "react";
import { AuthService } from "../services/authservice";
import { showToast } from "../utils/showToast";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { getUser } from "../api/user";

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    checkUserLogin(setUser, setIsLoading, setUserData, userData);
  }, []);

  useEffect(() => {
    const deliveryRoleId = "66edc29a13059690d19d6ea9"; 
    if (user && user.role === deliveryRoleId) {
      showToast("Sólo los usuarios de tipo cliente cuentan con acceso a este sistema", false);
      AuthService.logout();
      navigate("/login");
    }
  }, [user, navigate]);

  return <AuthContext.Provider value={{user, userData, isLoading}}>{children}</AuthContext.Provider>;
}

async function checkUserLogin(setUser, setIsLoading, setUserData, userData) {
  const accessToken = AuthService.getAccessTokenApi();

  if (!accessToken) {
    const refreshToken = AuthService.getRefreshTokenApi();

    if (!refreshToken) {
      AuthService.logout();
      setUser(null)
      setIsLoading(false)
    } else {
      AuthService.refreshAccessToken(refreshToken).then(() => {
        // Después de refrescar el token, intentar obtener el usuario nuevamente
        checkUserLogin(setUser, setIsLoading, setUserData);
      });
    }
  } else {
    const user = jwtDecode(accessToken);
    setUser({...user, role: user.role._id})
    if (user.id && !userData) {
      const { data } = await getUser({ id: user.id });
      //console.log("setting user data: ", {data})
      setUserData(data)
    }
    setIsLoading(false)

   
  }
}

/**
 * Returns an object containing information about the authentication status and role of the current user.
 *
 * @returns {Object} An object with the following properties:
 * - isAuthenticated: A boolean indicating whether the user is authenticated or not.
 * - role: The role of the authenticated user.
 */
export function useAuthToken() {
  const getToken = () => localStorage.getItem("accessToken");
  const getUserData = () => {
    const token = getToken();
    if (!token) return null;
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Failed to decode token", error);
      return null;
    }
  };

  const userData = getUserData();

  return {
    isAuthenticated: !!userData,
    role: userData?.role._id,
  };
}