import { useState } from "react";

export default function SearchTerm({ placeholder, type, setSearchTerm }) {
  //console.log("termino buscado: ", setSearchTerm);
  const [searchValue, setSearchValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearchTerm(searchValue);
    }
  };

  return (
    <input
      type={type}
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      onKeyDown={handleKeyDown}
      className="w-full rounded-lg text-md border selection:none px-3 py-[7px] border-gray-200 !outline-none"
    />
  );
}
