import Axios from "../config/axios-v1-config";


  //startDate = defaultStartDate(), endDate = new Date(), query = ""

export const getPickups = async ({startDate,endDate}) =>{
    const params = new URLSearchParams()
    params.append("startDate", startDate)
    params.append("endDate", endDate)
    const {data} = await Axios.get(`pickups?`+params.toString())
    return data.data
  }

export const filterPickups = (pickups, {query, municipality}) => {
  if(query == "") return pickups
  //console.log(pickups[0].pickups.map(e => e.address).some(e => e.includes(query)))
  return pickups.filter(pickup => pickup.delivery.name.includes(query) || pickup.delivery.lastName.includes(query) || pickup.pickups.map(e => e.address).some(e => e.includes(query)) || pickup.pickups.map(e => e.municipality).some(e => e.includes(query)))
  }