// import images
export const vehicles = {
  title: 'Vehículos disponibles',
  subtitle: 'Descubre nuestra flota de vehículos para tus necesidades de transporte.',
  items: [
    {
      id: 0,
      name: 'Camión',
      plate: 'AB123CD',
      type: 'Carga Pesada',
      maxWeight: '5000',
      maxVolume: '30',
      maxPoints: '5',
    },
    {
      id: 1,
      name: 'Furgoneta',
      plate: 'EF456GH',
      type: 'Carga Liviana',
      maxWeight: '1500',
      maxVolume: '15',
      maxPoints: '3',
    },
    {
      id: 2,
      name: 'Auto',
      plate: 'IJ789KL',
      type: 'Personal',
      maxWeight: '400',
      maxVolume: '5',
      maxPoints: '1',
    },
    {
      id: 3,
      name: 'Moto',
      plate: 'MN012OP',
      type: 'Motocicleta',
      maxWeight: '200',
      maxVolume: '1',
      maxPoints: '1',
    },
    {
      id: 4,
      name: 'Bicicleta',
      plate: 'QR345ST',
      type: 'Bicicleta',
      maxWeight: '50',
      maxVolume: '0.5',
      maxPoints: '1',
    },
    {
      id: 5,
      name: 'Helicóptero',
      plate: 'UV678WX',
      type: 'Aéreo',
      maxWeight: '5000',
      maxVolume: '50',
      maxPoints: '5',
    },
    {
      id: 6,
      name: 'Barco',
      plate: 'YZ901AB',
      type: 'Marítimo',
      maxWeight: '10000',
      maxVolume: '100',
      maxPoints: '7',
    },
    {
      id: 7,
      name: 'Avión',
      plate: 'CD234EF',
      type: 'Aéreo',
      maxWeight: '20000',
      maxVolume: '200',
      maxPoints: '10',
    },
    {
      id: 8,
      name: 'Triciclo',
      plate: 'GH567IJ',
      type: 'Liviano',
      maxWeight: '100',
      maxVolume: '2',
      maxPoints: '2',
    },
    {
      id: 9,
      name: 'Carreta',
      plate: 'KL890MN',
      type: 'Remolque',
      maxWeight: '8000',
      maxVolume: '60',
      maxPoints: '6',
    },
  ],
};



export const vehiclesTypes = [
    {
      label: 'Carga Pesada',
      value: 'Carga Pesada',
    },
    {
      label: 'Carga Liviana',
      value: 'Carga Liviana',
    },
    {
      label: 'Personal',
      value: 'Personal',
    },
    {
      label: 'Motocicleta',
      value: 'Motocicleta',
    },
    {
      label:'Bicicleta',
      value: 'Bicicleta',
    },
    {
      label: 'Aéreo',
      value: 'Aéreo',
    },
    {
      label: 'Marítimo',
      value: 'Marítimo',
    },
    {
      label: 'Aéreo',
      value: 'Aéreo',
    },
    {
      label: 'Liviano',
      value: 'Liviano',
    },
    {
      label: 'Remolque',
      value: 'Remolque',
    },
  ];
