import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Links from "../links";
import Divider from "../divider";
import useAuth from "../../hooks/useAuth";
import { iconList } from "../../utils/iconList";
import { AuthService } from "../../services/authservice";
import { useSelectedItem } from "./SelectedItemContext";
import Spinner from "components/spinner/spinner";

export default function Menu({ fetch }) {
  const { selectedItem } = useSelectedItem();
  const { user } = useAuth();
  const { pathname } = useLocation();
  // const [userData, setUserData] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const toggle = () => setShowDropdown((prevState) => !prevState);

  const logOut = () => {
    AuthService.logout();
    window.location.replace("/login");
  };

  // DvlprChris
  const [config, setConfig] = useState(null);
  const [storedColor, setStoredColor] = useState(null);

  console.log({"user: ": user})


  if(!user) return <Spinner/>

  return (
    <div className="layoutContainer">
      {/* <img src={Logo} alt="logo" className="layoutContainer__mainLogo" /> */}
      <img
        src={iconList.logoBeartrack}
        alt="logo"
        width="170"
        height="50"
        className=""
      />
      {/* aca neuvo codigo - dvlprchris */}
      {selectedItem === "envios" && (
        <>
          {/* Contenido que se mostrará solo cuando selectedItem sea "envios" */}
          <h1 className="font-bold translate-y-5">Hola, {user.name}</h1>
          <div className="layoutContainer__dividers">
            <div className="layoutContainer__otsTitle"></div>
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                package_2
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                PEDIDOS
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
            <Links
              content="Resumen"
              className="layoutContainer__linkContainer__link"
              selected={pathname === "/admin"}
              navigating="/admin"
            />
            {/* <Links
              content="Todos los pedidos"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/home")}
              navigating="/home"
            /> */}
          </div>
          <div className="layoutContainer__dividers">
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                description
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                INFORMES
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
            <Links
              content="Crear informe"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/inform")}
              navigating="/admin/inform"
            />
            {/* <Links
              content="Ver estadísticas"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/routssses")}
              navigating="/admin/routssses"
            /> */}
          </div>
          <Divider />
            <div className="layoutContainer__titles">
              <span style={{ display: "flex", alignItems: "start" }}>
                <span
                  className="material-symbols-outlined text-sm"
                  style={{
                    paddingLeft: "0",
                    marginRight: "5px",
                    fontSize: "",
                    // color: "#F8612C",
                    color: "black",
                  }}
                >
                  route
                </span>
                <span
                  style={{ fontSize: "" }}
                  className="text-black font-bold text-sm"
                >
                  RETIROS
                </span>
              </span>
            </div>

            <div className="layoutContainer__linkContainer">
               <Links
                content="Administrar retiros"
                className="layoutContainer__linkContainer__link"
                selected={pathname.includes("/admin/adminWithdrawals")}
                navigating="/admin/adminWithdrawals"
              />
              <Links
                content="Retiros realizados"
                className="layoutContainer__linkContainer__link"
                selected={pathname.includes("/admin/pickups")}
                navigating="/admin/pickups"
              />
            </div>
          <Divider />
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                add
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                ADICIONALES
              </span>
            </span>
          </div>

          <div className="layoutContainer__linkContainer">
          {/* <Links
              content="Integraciones"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/integrations")}
              navigating="/home/integrations"
            /> */}
             <Links
              content="Preguntas frecuentes"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/faqs")}
              navigating="/admin/faqs"
            />
          </div>

        </>
      )}
      {selectedItem === "usuarios_flota" && (
        <>
          {/* Contenido que se mostrará solo cuando selectedItem sea "envios" */}
          <div className="layoutContainer__dividers">
            <div className="layoutContainer__otsTitle"></div>
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                person
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                USUARIOS
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
            <Links
              content="Usuarios activos"
              className="layoutContainer__linkContainer__link"
              selected={pathname === "/admin/users"}
              navigating="/admin/users"
            />
            <Links
              content="Usuarios inactivos"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/lock")}
              navigating="/admin/lock"
            />
            <Links
              content="Usuarios pendientes"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/pendent")}
              navigating="/admin/pendent"
            />
          </div>
          <div className="layoutContainer__dividers">
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                local_shipping
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                FLOTA
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
          <Links
              content="En vivo"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/live-routes")}
              navigating="/admin/live-routes"
            />
            <Links
              content="Listado de vehículos"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/inform")}
              navigating="/admin/inform"
            />
          </div>
   
        </>
      )}
      {selectedItem === "rutas" && (
        <>
          {/* Contenido que se mostrará solo cuando selectedItem sea "rutas" */}
          <div className="layoutContainer__dividers">
            <div className="layoutContainer__otsTitle"></div>
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                map
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                RUTAS
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
            <Links
              content="Crear rutas"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/createRoute")}
              navigating="/admin/createRoute"
            />
            <Links
              content="Administrar flota"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/adminFleet")}
              navigating="/admin/adminFleet"
            />
            <Links
              content="Administrar rutas"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/adminRoute")}
              navigating="/admin/adminRoute"
            />
          </div>
       
        </>
      )}
      {/* aca neuvo codigo - dvlprchris */}
      {/* <div className="layoutContainer__dividers">
        <div className="layoutContainer__otsTitle">
          {user?.businessName
            ? user.businessName
            : `${user?.name} ${user?.lastName}`}
        </div>
        <Divider />
      </div>
      <div className="layoutContainer__titles">PEDIDOS</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Resumen"
          className="layoutContainer__linkContainer__link"
          selected={pathname === "/admin"}
          navigating="/admin"
        />
      </div>
      <div className="layoutContainer__dividers">
        <Divider />
      </div>
      <div className="layoutContainer__titles">INFORMES</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Crear informe"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/inform")}
          navigating="/admin/inform"
        />
      </div>
      <Divider />
      <div className="layoutContainer__titles">USUARIOS</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Listado de usuarios"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/users")}
          navigating="/admin/users"
        />
        <Links
          content="Usuarios inactivos"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/lock")}
          navigating="/admin/lock"
        />
      </div>
      <Divider /> */}
      
      {/* dvlprchris - 2024 */}
      {/* path: "/createRoute"
      path: "/adminFleet"
      path: "/adminRoute" */}
      {/* <div className="layoutContainer__titles">RUTAS y FLOTA</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Crear rutas"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/createRoute")}
          navigating="/admin/createRoute"
        />
        <Links
          content="Administrar flota"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/adminFleet")}
          navigating="/admin/adminFleet"
        />
        <Links
          content="Administrar rutas"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/adminRoute")}
          navigating="/admin/adminRoute"
        />
      </div>
      <Divider /> */}
      {/* dvlprchris - 2024 */}

      {/* <div className="layoutContainer__titles">Retiros</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Administrar rutas"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/routes")}
          navigating="/admin/routes"
        />
      </div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Retiros realizados"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/pickups")}
          navigating="/admin/pickups"
        />
      </div>
      <Divider /> */}
      {/* <div className="layoutContainer__titles">PREGUNTAS</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Preguntas frecuentes"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/faqs")}
          navigating="/admin/faqs"
        />
      </div> */}
      {/* comentar para pruebas - ksandoval */}
      {/* <div className="d-flex align-items-center justify-content-center">
           <img
          src={iconList["defaultAvatar"]}
          className="layout-image"
          alt="userImg"
        />
        <UserAvatar storedColor={storedColor} className="layout-image" alt="customUserImg" />
        <Dropdown
          isOpen={showDropdown}
          toggle={toggle}
          direction="down"
          className="layout-image-arrow d-flex align-items-center"
        >
          <DropdownToggle>
            <img src={ArrowDown} alt="arrowDown" className="image-arrow" />
          </DropdownToggle>
          <DropdownMenu className="custom-dropdown">
            <DropdownItem className="dropdown-layout-text mb-1">
              <div className="flex items-center gap-1">
                <img
                  src={iconList["info"]}
                  alt="info"
                  className="layout-icon mr-2"
                />{" "}
                Ver mi cuenta
              </div>
            </DropdownItem>
            <Divider />
            <DropdownItem
              className="dropdown-layout-text mt-1"
              onClick={() => logOut()}
            >
              <div className="flex items-center gap-1">
                <img
                  src={iconList["close"]}
                  alt="close"
                  className="layout-icon mr-2"
                />{" "}
                Cerrar sesión
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Button
          block
          color="white"
          className="bg-orange-500"
          onClick={logOut}
        >
          Cerrar sesión
        </Button>
        
      </div> */}

      <div className="custom-layout-button">
        {/* <Button
          block
          color="dark"
          className="bg-black"
          onClick={() => setOpenModal(true)}
        >
          Crear pedido
        </Button> */}
        <Divider></Divider>
        <div className="d-flex align-items-center justify-content-center mt-2">
          <span
            className="material-symbols-outlined"
            style={{
              border: "none",
              marginRight: "0",
              fontSize: "2rem",
              color: "black",
              backgroundColor: "transparent !important",
            }}
          >
            account_circle
          </span>
          <div
            className=""
            style={{
              border: "none",
              margin: "0",
              paddingLeft: "5px",
              fontSize: "10px",
              color: "black",
              backgroundColor: "transparent !important",
            }}
          >
            <h1 className="font-bold">{user.logisticCompany.name}</h1>
            <p>{user.email}</p>
            {/* <h1 className="font-bold">GAMU SPA</h1>
            <p>gamuchilespa@gmail.com</p> */}
          </div>
          <Dropdown
            isOpen={showDropdown}
            toggle={toggle}
            direction="down"
            className=""
          >
            <DropdownToggle className="border-none">
              <span
                className="material-symbols-outlined"
                style={{
                  border: "none",
                  marginRight: "0",
                  fontSize: "1rem",
                  color: "black",
                  backgroundColor: "transparent !important",
                }}
              >
                chevron_right
              </span>
            </DropdownToggle>

            <DropdownMenu className="">
              <DropdownItem
                className="dropdown-layout-text mb-1"
                onClick={() => navigate("/home/my-account")}
              >
                <div className="flex items-center gap-1">
                  <img
                    src={iconList["info"]}
                    alt="info"
                    className="layout-icon mr-2"
                  />{" "}
                  Ver mi cuenta
                </div>
              </DropdownItem>
              <Divider />
              <DropdownItem
                className="dropdown-layout-text mt-1"
                onClick={() => logOut()}
              >
                <div className="flex items-center gap-1">
                  <img
                    src={iconList["close"]}
                    alt="close"
                    className="layout-icon mr-2"
                  />{" "}
                  Cerrar sesión
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
