import React, { useState, useEffect, useCallback } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { iconList } from "../../../utils/iconList";
import useAuth from "../../../hooks/useAuth";
import { showToast } from "../../../utils/showToast";
import { posibleIntegrations } from "../../../utils/integrations";
import useModal from "../../../store/modal";
import { getMeliAuthURL, getUserIntegrations, integrateMercadoLibre, addFalabella } from "../../../api/integrations"; // Importa las funciones desde api/integrations.js

const AddIntegrationButton = ({ active, onClick }) => (
  <div
    onClick={active ? onClick : () => {}}
    className={`mt-2 w-full border-[2px] rounded-lg   text-center ${
      active
        ? "bg-[#FEF3D4] border-[#EFC02D] text-sm py-[14px] cursor-pointer font-bold text-black"
        : "text-gray-500 py-[12px] cursor-not-allowed"
    }`}
  >
    {active ? "VINCULAR NUEVA CUENTA" : "Próximamente"}
  </div>
);

const Integration = ({
  integration,
  deleteIntegration,
  changeAlias,
  clientId,
  handleModalClose,
}) => {
  const openModal = useModal((state) => state.openModal);
  return (
    <>
      <div className="w-full flex flex-col relative bg-[#F9F9F9] rounded-lg py-1 px-2 font-bold">
        <span
          onClick={() =>
            openModal("changeAlias", { ...integration, changeAlias, clientId })
          }
          className="text uppercase text-black cursor-pointer hover:scale-[1.02] w-fit transition"
        >
          {integration?.sender_id
            ? integration?.sender_id
            : integration?.url
            ? integration?.url.slice(8)
            : integration.alias}
        </span>
        <span className="text-sm text-green-500">vinculado</span>
      </div>
    </>
  );
};

// Componente principal de Integraciones
export default function Integrations() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [updateUser, setUpdateUser] = useState(true);
  const { user } = useAuth();

  // Obtener parámetros de la URL
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");
  const error = urlParams.get("error");

  // Función para eliminar una integración
  const deleteIntegration = useCallback((alias, integrationName) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      integrations: prevUserData.integrations.filter(
        (e) => e.alias !== alias || e.integrationName !== integrationName
      ),
    }));
  }, []);
  // Función para cambiar el alias de una integración
  const changeAlias = useCallback((oldAlias, newAlias) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      integrations: prevUserData.integrations.map((e) =>
        e.alias === oldAlias ? { ...e, alias: newAlias } : e
      ),
    }));
  }, []);


  // Definición de acciones para cada integración
  const ACTIONS = {
    MercadoLibre: async () => {
      const values = {
        clientId: user?.id,
      };
      try {
        // Solicitar la URL de autenticación de MercadoLibre desde el backend
        const response = await getMeliAuthURL(values);
        if (response.error) {
          console.error("Error obteniendo la URL de autenticación:", response.error);
          showToast("Error al obtener la URL de autenticación de MercadoLibre", false);
        } else {
          const { authURL } = response;
          // Redirigir al usuario a la URL de autenticación de MercadoLibre
          window.location.assign(authURL);
        }
      } catch (err) {
        console.error("Error inesperado:", err);
        showToast("Ocurrió un error inesperado al iniciar la integración con MercadoLibre", false);
      }
    },
  };

  // Efecto para obtener las integraciones del usuario al cargar el componente
  useEffect(() => {
    const fetchUserData = async () => {
      if (updateUser && user) {
        try {
          const data = await getUserIntegrations({ clientId: user?.id });

          if (data.error) {
            showToast("No tienes cuentas vinculadas", false);
          } else if (data.ok) {
            // Actualizar las integraciones del usuario
            setUserData((prevData) => ({
              ...prevData,
              integrations: data.integrations,
            }));
          } else {
            showToast("Error desconocido al obtener las integraciones", false);
          }
        } catch (err) {
          console.error("Error fetching integrations:", err);
          showToast("Error al obtener las integraciones", false);
        }
      }
    };

    if (user) {
      fetchUserData();
    }
  }, [user, updateUser]);


  // Efecto para manejar el parámetro 'code' en la URL y completar la integración
  useEffect(() => {
    const verifyMLCode = async () => {
      if (!code) return;

      try {
        const values = {
          code,
          clientId: user.id, // Asegúrate de que el usuario está autenticado y tiene un ID
        };

        const data = await integrateMercadoLibre(values);
        //console.log("date",data) // Verifica la respuesta del backend
        if (data.error) {
          showToast("Error al vincular su cuenta con Mercado Libre", false);
          return;
        }

        if (!data.ok) {
          showToast(data.message || "Error desconocido durante la integración", false);
          return;
        }

        showToast("Cuenta vinculada con Mercado Libre", true);
        setUpdateUser(true);
        navigate("/home/integrations", { replace: true });
      } catch (err) {
        console.error("Error al vincular MercadoLibre:", err);
      }
    };

    if (code) {
      verifyMLCode();
    }

    // Manejo de errores de redirección
    if (error) {
      showToast("Ha ocurrido un error al vincular su cuenta con Mercado Libre", false);
      navigate("/home/integrations", { replace: true });
    }
  }, [code, error, user, navigate]);

  const client = useAuth((state) => state.clientId);
  const openModal = useModal((state) => state.openModal);

  // Manejar el clic en el botón de integración
  const handleIntegrationClick = (name) => {
    //console.log(name);
    if (name === "MercadoLibre") {
      ACTIONS.MercadoLibre();
    } else if (name === "WooCommerce") {
      openModal("AddWoocommerce", { handleModalClose });
    } else if (name === "Shopify") {
      openModal("AddShopify", { handleModalClose, client });
    } else if (name === "Falabella") {
      openModal("AddFalabella", { handleModalClose });
    } else if (name === "Sendu") {
      openModal("AddSendu", { handleModalClose });
    }
  };
  // Manejar el cierre del modal
  const handleModalClose = () => {
    setUpdateUser(true);
  };

  return (
    <div className="w-100 all-users-container">
      <Breadcrumb className="custom-breadcrumb-container">
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate("/home")}
        >
          <img src={iconList.home} alt="home" className="mr-2" />
          <span>Inicio</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Integraciones</span>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <span className="d-flex">
          <h2 className="h2-custom-size all-users-container__title">
            Integraciones
          </h2>
        </span>
      </div>

      <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
        {posibleIntegrations?.map((integration, index) => (
          <div key={index} className="col-span-1">
            <div className="card-custom">
              <div className="text-center mb-2 space-y-2">
                <img
                  src={iconList[integration.icon]}
                  alt={integration.name}
                  className="bg-gray-50 p-5 w-full aspect-square rounded-3xl"
                />
                <h5 className="text-lg font-bold">{integration.name}</h5>
                <AddIntegrationButton
                  active={integration.value}
                  onClick={() => handleIntegrationClick(integration.name)}
                />
              </div>
              <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5">
                <ul className="list-none p-0 m-0">
                  {userData.integrations
                    ?.filter((i) => i.integrationName === integration.name)
                    .map((integ, i) => (
                      <li
                        key={i}
                        className="border-b border-gray-200 last:border-0"
                      >
                        <Integration
                          integration={integ}
                          deleteIntegration={deleteIntegration}
                          changeAlias={changeAlias}
                          clientId={user.id}
                          handleModalClose={handleModalClose}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
