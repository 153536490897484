import FileInput from "../fileInput";
import useModal from "../../store/modal";
import { useState } from "react";
import { loadCustomFile } from "../../api/packages";
import { toast } from "react-toastify";

function CustomFile({ closeModal, userId, fetch, setPackagesCreated }) {
  const openModal = useModal((state) => state.openModal);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleFileUpload = (e) => {
    const file = e;
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      setFile(data);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <>
      <h1 className="font-semibold text-center text-xl mt-2">
        Cargar plantillas personalizadas
      </h1>
      <div className="flex my-3 justify-center items-center gap-2">
        {/* <select className="p-2 text-xl " name="" id="">
                    <option value="">Plantilla</option>
                </select> */}
        <button
          onClick={() => {
            openModal("createCustomTable", { userId });
            closeModal(false);
          }}
          className="px-2 rounded-md text-lg bg-yellow-200 hover:scale-105 transition"
        >
          Modificar plantilla
        </button>
      </div>
      <div className="flex items-center justify-center mt-4 mb-3">
        <FileInput
          onSelectDocument={(file) => {
            handleFileUpload(file[0]);
          }}
        />
      </div>
      {file && (
        <>
          {/* <span>{file.name}</span> */}
          <div
            className={` text-gray-200 p-2 rounded-md hover:scale-[1.01] transition hover:shadow-lg cursor-pointer text-center ${
              loading
                ? "animate-pulse bg-gray-700 cursor-not-allowed"
                : "bg-black"
            }`}
            onClick={async () => {
                try {
                  setLoading(true);
                  const res = await loadCustomFile({ file, user: userId });
                  //console.log(res);
                  if (res.data.ok) {
                    toast.success("Cargado con éxito");
                    setPackagesCreated(res.data.packages);
                    fetch(true);
                    closeModal(false);
                  }
                } catch (error) {
                  console.error(error);
                  if (error.response && error.response.data && error.response.data.error && error.response.data.error.uncompletedCells) {
                    toast.error(
                      "Faltan completar las siguientes celdas: " +
                        error.response.data.error.uncompletedCells.join(", ")
                    );
                  } else {
                    toast.error("Error al cargar.");
                  }
                } finally {
                  setLoading(false);
                }
              }}
          >
            Cargar
          </div>
        </>
      )}
    </>
  );
}

export default CustomFile;
