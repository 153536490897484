import React, { useState } from "react";

import { FunctionButton } from "../../components/buttons/buttons";
import { FullInput } from "../../components/inputs";

import PersonIcon from "../../assets/person.svg";
import ArrowUp from "../../assets/expand.svg";
import ArrowDown from "../../assets/unexpand.svg";
import PackageIcon from "../../assets/package.svg";
import ClientIcon from "../../assets/storefront.svg";
import LocationIcon from "../../assets/location_away.svg";
import { useEffect } from "react";
import useModal from '../../store/modal'
import { getPickups, filterPickups } from "../../api/pickups";

function defaultStartDate() {
  const today = new Date();
  const defaultStartDate = new Date(today);
  defaultStartDate.setDate(today.getDate() - 7);
  return defaultStartDate;
}

const TextIcon = ({ text, icon, className = "" }) => {
  return (
    <h3 className={`flex gap-2 ${className}`}>
      <img src={icon} alt="" />
      <span>{text}</span>
    </h3>
  );
};

const Controls = ({filters,setFilters}) => {
  const {openModal, props} = useModal(state => state)
  return (
    <>
      <div className="mb-2 flex justify-between">
        <FunctionButton content={"Seleccionar todos"} />
        <div className="flex gap-2">
          <FunctionButton onClick={() => {openModal("selectDates", {startDate: filters.startDate, endDate: filters.endDate})}} content={"Fecha"} />
          {/* <FunctionButton content={"Comuna"} /> */}
        </div>
      </div>
      <FullInput value={filters.query} onChange={(e) => {
        setFilters({...filters, query: e.target.value})
      }} placeholder={"Buscar por cliente, direccion o comuna"} />
    </>
  );
};

const Pickup = ({ pickupData, isExpanded, toggleExpand }) => {
  const { pickups, delivery } = pickupData;

  return (
    <div className="p-3 border-[1px] rounded-lg border-[#DFE2E6]">
      <div className="flex justify-between">
        <TextIcon
          className="font-bold"
          icon={PersonIcon}
          text={delivery.name}
        />

        <img
          onClick={toggleExpand}
          src={isExpanded ? ArrowDown : ArrowUp}
          alt=""
        />
      </div>
      {isExpanded && (
        <div className="flex gap-2 mt-2 flex-col">
          {pickups.map((pickup) => {
            return (
              <div>
                {/* {pickup.companyName} */}
                <TextIcon
                  className="font-bold"
                  text={pickup.businessName || `${pickup.name} ${pickup.lastName}`}
                  icon={ClientIcon}
                />
                <TextIcon text={pickup.address} icon={LocationIcon} />
                {pickup.packages.map((pack) => (
                  <TextIcon text={pack} icon={PackageIcon} />
                ))}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const PickupsList = ({filters}) => {
  const [expandedList, setExpandedList] = useState([]);
  const [pickupList, setPickupList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getPickups(filters).
      then((data) => {
        console.log(data)
        setPickupList(data);
      });
  }, []);

  useEffect(() => {
    //console.log(filters)
    //console.log(pickupList)
    setFilteredData(filterPickups(pickupList, filters))
  }, [filters])

  const toggleExpand = (id) => {
    setExpandedList(
      expandedList.includes(id)
        ? expandedList.filter((el) => el !== id)
        : [...expandedList, id]
    );
  };
  return (
    <div className="mt-2 gap-2 flex flex-col h-[90%] overflow-y-auto">
      {filteredData &&
        filteredData.map((pickup) => (
          <Pickup
            pickupData={pickup}
            isExpanded={expandedList.includes(pickup.id)}
            toggleExpand={() => {
              toggleExpand(pickup.id);
            }}
          />
        ))}
    </div>
  );
};

export default function PickupsContent() {
  const [filters, setFilters] = useState({
    query: "",
    startDate: defaultStartDate(),
    endDate: new Date(),
    municipality: ""
  })
  return (
    <section className="border-[1px] w-full h-[85vh] rounded-lg p-3 border-[#DFE2E6]">
      <Controls filters={filters} setFilters={setFilters} />
      <PickupsList filters={filters}/>
    </section>
  );
}
