import { utils, writeFile } from "xlsx";
import { packageStatus } from "./packageStatus";
import { formatRut } from "./formatter";

export const downloadExcel = async ({ dataToExcel, fileName }) => {
  if (!Array.isArray(dataToExcel) || dataToExcel.length === 0) {
    console.warn('No hay datos para descargar.');
    return;
  }
  
  try {
    const data = dataToExcel;
    let filename = fileName;
    let wb = utils.book_new();
    let ws_name = "hoja 1";
    let ws_data = [
      [
        "#",
        "FECHA DE CREACIÓN DEL ENVÍO",
        "COMENTARIO DEL PAQUETE",
        "NÚMERO DE SEGUIMIENTO",
        "NOMBRE DEL DESTINATARIO",
        "NÚMERO DEL DESTINATARIO",
        "ESTADO DEL PAQUETE",
        "FECHA DE ENTREGA",
        "NÚMERO DE VENTA",
        "NOMBRE DEL REPARTIDOR",
        "DIRECCIÓN DE ORIGEN",
        "NOMBRE DEL VENDEDOR",
        "NOMBRE DEL NEGOCIO",
        "NOMBRE DEL RECEPTOR",
        "RUT DEL RECEPTOR",
        "DIRECCIÓN DE DESTINO",
        "COMUNA DE DESTINO",
        "COMENTARIO DEL ENVÍO",
      ],
    ];
    data?.forEach((item, index) => {
      ws_data.push([
        index + 1,
        item?.createdAt
          ? new Date(item.createdAt).toLocaleString("es-CL", {
              timeZone: "America/Santiago",
              hour12: false,
            })
          : null,
        item?.comments,
        item?.followingNumber,
        item?.receiver_name,
        item?.receiver_phone,
        packageStatus[item?.status],
        item?.objectStatus?.updatedAt
          ? new Date(item.objectStatus.updatedAt).toLocaleString()
          : "",
        item?.order_id,
        `${item?.assignTo?.name || ""} ${item?.assignTo?.lastName || ""}`,
        `${item?.senderInfo?.senderAddress}, ${item?.senderInfo?.senderMunicipality}`,
        `${item?.senderUser?.senderName || ""} ${item?.senderUser?.senderLastName || ""}`,
        item?.senderInfo?.businessName,
        `${item?.realReceiver?.name || ""} ${item?.realReceiver?.lastName || ""}`,
        formatRut(item?.realReceiver?.rut) || "",
        item?.shipping_address?.address_line,
        item?.shipping_address?.city?.name,
        item?.shipping_address?.comment,
      ]);
    });
    let ws = utils.aoa_to_sheet(ws_data);
    var col = [
      { wpx: 50 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
    ];
    ws["!cols"] = col;
    utils.book_append_sheet(wb, ws, ws_name);
    writeFile(wb, filename + ".xlsx");
  } catch (error) {
    console.error(error);
  }
};
