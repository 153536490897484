import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import { getActiveFaqs } from "../../../api/faqs";

export default function Faqs() {
  const [open, setOpen] = useState(null); // Cambia el estado inicial a null para evitar conflictos
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carga

  const toggle = (id) => {
    setOpen((prevOpen) => (prevOpen === id ? null : id)); // Permite cerrar al hacer clic en un item abierto
  };

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const data = await getActiveFaqs();
        setFaqs(data?.faqs || []); // Asegura que sea un array vacío si no hay datos
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // Termina la carga
      }
    };
    fetchFaqs();
  }, []);

  if (loading) {
    return (
      <div className="w-100 all-users-container mt-6">
        <div className="d-flex justify-content-center">
          <span className="loading-spinner">Cargando preguntas frecuentes...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 all-users-container mt-6">
      <div className="d-flex justify-content-between">
        <span className="d-flex">
          <h2 className="h2-custom-size all-users-container__title">
            Preguntas frecuentes
          </h2>
        </span>
      </div>
      {Array.isArray(faqs) && faqs.length > 0 ? (
        <div className="custom-accordion-container px-3">
          <Accordion open={open} toggle={toggle} flush>
            {faqs.map((faq) => (
              <AccordionItem className="p-3" key={faq._id}>
                <AccordionHeader targetId={faq._id}>
                  <span className="custom-header-text-form">{faq.title}</span>
                </AccordionHeader>
                <AccordionBody accordionId={faq._id}>
                  <span className="custom-body-text-form">{faq.content}</span>
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      ) : (
        <span>No se han encontrado preguntas.</span>
      )}
    </div>
  );
}
