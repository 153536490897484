import {create} from 'zustand'

const useShippingAddressStore = create(
  (set) => ({
    selectedShippingAddresses: {},
    
    setShippingAddress: (clientId, address) => set((state) => ({
      selectedShippingAddresses: {
        ...state.selectedShippingAddresses,
        [clientId]: address
      }
    })),
    
    clearShippingAddress: (clientId) => set((state) => {
      const { [clientId]: removed, ...remaining } = state.selectedShippingAddresses;
      return { selectedShippingAddresses: remaining };
    }),
    
    clearAllShippingAddresses: () => set({ selectedShippingAddresses: {} })
  })
);

export default useShippingAddressStore;