/* eslint-disable */
import React, { useCallback, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import jwtDecode from "jwt-decode";
import { useForm, Controller } from "react-hook-form";
// import { usewindow.location.replace } from "react-router-dom";
import { Login as LoginApi } from "../../api/auth";
import { showToast } from "../../utils/showToast";
import { getConfig } from "../../api/configService";
import BeartrackLogo from "../../assets/beartrack/beartrack_logo.png";

export default function Login() {
  const methods = useForm({ mode: "onSubmit" });
  const [loading, setLoading] = useState(false);

  // DvlprChris
  const [config, setConfig] = useState(null);
  const [storedColor, setStoredColor] = useState(null);
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const serviceConfig = await getConfig();
        setConfig(serviceConfig);
        setStoredColor(serviceConfig.color);
        localStorage.setItem("appConfig", JSON.stringify(serviceConfig));
        setConfigLoaded(true);

      } catch (error) {
      }
    };
    fetchConfig();
  }, []);


  const submitValues = async (values) => {
      setLoading(true);
      const { data, error } = await LoginApi(values);
      if (error) {
        showToast(error.message, false);
        setLoading(false);
        return;
      }

      if (data.message !== "El usuario no se encuentra registrado.") {
        const userData = await jwtDecode(data?.accessToken);

        if (userData?.role?.name === "delivery") {
          showToast(
            "De momento solo pueden ingresar usuarios de tipo cliente o administrador.",
            false
          );
          setLoading(false);
        }
        if (userData?.role?.type === "company") {
          showToast("Login exitoso", true);
          setLoading(false);
          window.location.replace("/home");
        }
        if (userData?.role?.type === "logistic" && userData?.role?.name !== "delivery") {
          showToast("Login exitoso", true);
          setLoading(false);
          window.location.replace("/admin");
        }
      }
      if (error) {
        showToast(error.message, false);
        setLoading(false);
      }}
    
  return (
    <Container className="vh-100">
      <Row
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ overflow: "hidden" }}
      >
        <Col xs="6" md="6" xl="6">
          <div className="login-card-style mt-5">
            <div className="w-100 d-flex justify-content-center">
              <Card className="m-0 login-card-over">
                <CardBody className="p-4">
                  <a
                    href="#"
                    className="d-flex justify-content-center mt-3 mb-4"
                  >
                    <img
                      src= {BeartrackLogo}
                      alt="logo"
                      style={{ width: "70%" }}
                    />
                  </a>
                  <h3 className="mb-3 h3-custom-size">
                    Bienvenido a BearTrack!
                  </h3>
                  <h3 className="mb-3 login-custom-size">
                    Inicia sesión para consultar tus pedidos
                  </h3>
                  <Form onSubmit={methods.handleSubmit(submitValues)}>
                    <FormGroup>
                      <div>
                        <Label className="mt-3">Email</Label>
                        <Controller
                          name="email"
                          control={methods.control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="email"
                              className="p-2 px-3"
                              onChange={(e) => {
                                field.onChange(e.target.value.toLowerCase());
                              }}
                            />
                          )}
                          rules={{ required: "Ingrese correo" }}
                        />
                        {methods?.formState?.errors?.email && (
                          <Label className="text-danger">
                            * {methods?.formState?.errors?.email?.message}
                          </Label>
                        )}
                      </div>
                      <div>
                        <Label className="mt-3">Contraseña</Label>
                        <Controller
                          name="password"
                          control={methods.control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="password"
                              placeholder="············"
                              className="p-2 px-3"
                            />
                          )}
                          rules={{ required: "Ingrese contraseña" }}
                        />
                        {methods?.formState?.errors?.password && (
                          <Label className="text-danger">
                            * {methods?.formState?.errors?.password?.message}
                          </Label>
                        )}
                      </div>
                    </FormGroup>
                    <div className="w-100">
                      {loading ? (
                        <Button className="login-btn" block>
                          <Spinner size="sm" />
                        </Button>
                      ) : (
                        // Dvlprchris - utilziar el color de la API
                        <Button
                          style={{
                            color:"black",
                            backgroundColor: storedColor,
                            borderColor: storedColor,
                          }}
                          block
                        >
                          Iniciar sesión
                        </Button>
                      )}
                    </div>
                    <div className="w-full flex justify-end mt-1">
                      <a
                        href="/login/recovery"
                        className=" text-md text-yellow-500"
                      >
                        ¿Olvidaste tu contraseña?
                      </a>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
