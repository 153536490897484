import React, { useState, useCallback } from "react";
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Spinner } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircle } from "lucide-react";
import BeartrackLogo from "../../assets/beartrack/beartrack_logo.png";
import { confirmRecovery } from "../../api/auth";
import { showToast } from "../../utils/showToast";

export default function SetNewPassword() {
  const { control, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitValues = useCallback(async ({ password }) => {
    if (password.length < 6) {
      showToast("La contraseña debe tener al menos 6 caracteres.", false);
      return;
    }

    setLoading(true);
    setSuccess(false);
    try {
      await confirmRecovery({ password, token });

      showToast("¡Contraseña actualizada con éxito!", true);
      setSuccess(true);
      setTimeout(() => navigate("/login"), 2000);
    } catch (error) {
      showToast("Ocurrió un error, intente nuevamente.", false);
    } finally {
      setLoading(false);
    }
  }, [navigate, token]);

  return (
    <Container className="vh-100">
      <Row className="d-flex justify-content-center align-items-center vh-100" style={{ overflow: "hidden" }}>
        <Col xs="6" md="6" xl="6">
          <div className="login-card-style mt-5">
            <div className="w-100 d-flex justify-content-center">
              <Card className="m-0 login-card-over">
                <CardBody className="p-4">
                  <a href="#" className="d-flex justify-content-center mt-3 mb-4">
                    <img
                      src={BeartrackLogo}
                      alt="logo"
                      style={{ width: "70%" }}
                    />
                  </a>

                  <h3 className="mb-3 h3-custom-size text-center">
                    Escoja su nueva contraseña
                  </h3>

                  {success ? (
                    <div className="text-center">
                      <CheckCircle size={48} color="green" className="mb-3 mx-auto" />
                      <p className="text-success">
                        ¡Contraseña actualizada con éxito!
                      </p>
                    </div>
                  ) : (
                    <Form onSubmit={handleSubmit(submitValues)}>
                      <FormGroup>
                        <Label className="mt-3">Nueva Contraseña</Label>
                        <Controller
                          name="password"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Ingrese una contraseña válida." }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="password"
                              className="p-2 px-3"
                            />
                          )}
                        />
                        {errors.password && (
                          <Label className="text-danger">
                            * {errors.password.message}
                          </Label>
                        )}
                      </FormGroup>
                      <div className="w-100">
                        {loading ? (
                          <Button className="login-btn" block>
                            <Spinner size="sm" />
                          </Button>
                        ) : (
                          <Button
                            style={{
                              color: "black",
                              backgroundColor: "#007bff",
                              borderColor: "#007bff",
                            }}
                            block
                          >
                            Confirmar
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
