import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Spinner } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { CheckCircle } from "lucide-react";
import BeartrackLogo from "../../assets/beartrack/beartrack_logo.png";
import axios from "axios";
import { recoveryPassword } from "api/auth";

export default function PasswordReset() {
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitEmail = async ({ email }) => {
    setLoading(true);
    setSuccess(false);
    try {
      await recoveryPassword({email})
      setSuccess(true);
    } catch (error) {
      setSuccess(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="vh-100">
      <Row className="d-flex justify-content-center align-items-center vh-100" style={{ overflow: "hidden" }}>
        <Col xs="6" md="6" xl="6">
          <div className="login-card-style mt-5">
            <div className="w-100 d-flex justify-content-center">
              <Card className="m-0 login-card-over">
                <CardBody className="p-4">
                  <a href="#" className="d-flex justify-content-center mt-3 mb-4">
                    <img
                      src={BeartrackLogo}
                      alt="logo"
                      style={{ width: "70%" }}
                    />
                  </a>

                  <h1 className="mb-3 h3-custom-size text-center">
                    Recupera tu cuenta
                  </h1>

                  <h3 className="mb-3 login-custom-size">
                    Perdiste el accesso a tu cuenta? Recuperalo!
                  </h3>

                  {success ? (
                    <div className="text-center">
                      <CheckCircle size={38} color="green" className="mx-auto mb-2" />
                      <p className="text-success">
                        Si existe una cuenta vinculada al correo, recibirás un correo de confirmación.
                      </p>
                    </div>
                  ) : (
                    <Form onSubmit={handleSubmit(submitEmail)}>
                      <FormGroup>
                        <Label className="mt-3">Email</Label>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Ingrese un correo válido." }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="email"
                              className="p-2 px-3"
                            />
                          )}
                        />
                        {errors.email && (
                          <Label className="text-danger">
                            * {errors.email.message}
                          </Label>
                        )}
                      </FormGroup>
                      <div className="w-100">
                        {loading ? (
                          <Button className="login-btn" block>
                            <Spinner size="sm" />
                          </Button>
                        ) : (
                          <Button
                            style={{
                              color: "black",
                              backgroundColor: "#007bff",
                              borderColor: "#007bff",
                            }}
                            block
                          >
                            Recuperar cuenta
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
