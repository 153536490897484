import { getDeliveredPackages } from "api/routes";
import formatDate from "utils/formatDate";
import { create } from "zustand";

// Helper function to format date as YYYY-MM-DD


const useDeliveredStore = create((set) => ({
    delivered: {}, // Object with formatted date (YYYY-MM-DD) as keys
    fetchDelivered: async (date) => {
        try {
            const formattedDate = formatDate(date); // Format date as YYYY-MM-DD
            const deliveredPackages = await getDeliveredPackages(formattedDate);
            set((state) => ({
                ...state,
                delivered: {
                    ...state.delivered,
                    [formattedDate]: deliveredPackages, // Set the packages with formatted date as key
                },
            }));
        } catch (error) {
            console.error("Error fetching delivered:", error);
        }
    },
}));

export default useDeliveredStore;
