import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthProvider from "../../providers/authProviders";
import ClientMenu from "../../components/menu/clientMenu";
import NewClientMenu from "../../components/menu/newClientMenu";
import AdminMenu from "../../components/menu/adminMenu";
import NewAdminMenu from "../../components/menu/newAdminMenu";
import useAuth from "../../hooks/useAuth";
import { iconList } from "../../utils/iconList";
import TaildwindClientMenu from "../../components/menu/tailwindClientMenu";

// Mapea los ObjectId de roles con sus rutas correspondientes
const roleRoutes = {
  admin: "66edc1f613059690d19d6ea5",
  client: "66edc2fc13059690d19d6ead", // KAM o AM dependiendo del negocio
  delivery: "66edc29a13059690d19d6ea9",
  supervisor: "66edc25a13059690d19d6ea7",
  support: "66edc2c613059690d19d6eab",
  superadmin: "66edc32f13059690d19d6eb1", // Super administrador
};

export default function MainLayout({ routes }) {
  const { user, userData, isLoading } = useAuth();
  const [updatePackages, setUpdatePackages] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !isLoading) {
      // navigate("/login");
    }
  }, [user, isLoading, navigate]);

  return (
  <Container className="w-100 m-0 p-0" style={{ maxWidth: "100%", backgroundColor: "none", overflowX: "hidden" }}>
    <ToastContainer />
    <Row className="">
      <div className="custom-menu-layout">
        {user?.role === roleRoutes.client && <ClientMenu fetch={setUpdatePackages} />}
        {user?.role === roleRoutes.admin && <AdminMenu fetch={setUpdatePackages} />}
        {/* <AdminMenu fetch={setUpdatePackages} /> */}
      </div>

      <div className="custom-content-layout">
        <NewAdminMenu fetch={setUpdatePackages} />
          

        <Routes className="px-4">
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <AuthProvider>
                    <div className="content-main-container">
                      <route.element
                        update={updatePackages}
                        resetUpdate={setUpdatePackages}
                      />
                    </div>
                  </AuthProvider>
                }
              />
            ))}
          </Routes>
      
      </div>
    </Row>
  </Container>
  );
}
