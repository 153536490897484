import React from "react";
import { Label, Row, Col, Input, Button } from "reactstrap";
import JSzip from "jszip";
import { saveAs } from "file-saver";
// import { ZplTemplate } from "../../config/zplTemplate";
import { ZplTemplate } from "../../config/newZebraTemplate";
import { convertImageToZplWithLabelary } from "../../utils/newDownloadZebra";
import FormsDivider from "../formsDivider";
import SearchDropdown from "../searchDropdown";
import { posibleComs } from "../../utils/coms";
import { downloadPdf } from '../../api/downloadPdf'
import useAuth from "../../hooks/useAuth";
import { showToast } from '../../utils/showToast'
import { getConfig } from "../../api/configService";
import logo from '../../assets/OTS_logo.png';
import { multipleDownloadZPLFile } from '../../utils/downloadZebra'
// import { multipleDownloadZPLFile } from '../../utils/newDownloadZebra'
import fileName from "../../utils/fileName";
import QRCode from "react-qr-code";

function getFormattedDate() {
  const months = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  return `${day} - ${month}`;
}


export default function DownloadVoucher({ defaultData }) {
  const { user } = useAuth()

  console.log(defaultData);

  const downloadZplFormatNew = async () => {
    let zplFormat = ZplTemplate;
    const businessName = defaultData?.senderInfo?.businessName;
    const senderName = defaultData?.senderUser?.senderName ?? "";
    const senderLastName = defaultData?.senderUser?.senderLastName ?? ""; 
    const nameToUse = businessName || (senderName + " " + senderLastName);
    const receiverName = defaultData?.receiver_name ?? "";
    const receiverLastName = defaultData?.receiver_lastName ?? "";
    const receiverNameToUse = (receiverName + " " + receiverLastName)
    const fechaActual = getFormattedDate();

    let logoApp =
    ":::::::::X07,W07F8,V03FFC,V01FFE,P01C00380IF,O01FE01FC07FF8,O03FF1FFC03FFC,O01FF9FFE01FFE,P0FFCIF00IF,P07FE7FF807FF8,P03FF3FFC03FFC,P01FF9FFE01FFE,Q0FFCIF00IF,P067FE7FF807FF8,O07F3FF3FFC03FFC,O0FF9FF9FFE01FFE,O07FCFFDIF00IF,O03FE7FEIF807FF8,O01FF3FF7FFC03FF8,P0FF9FFBFFE01FC,P07FCFFDIF00C3DKF8KFC00FE007JFC,P03FE7FEIF801F9KFDKFC01FF007JFE,P01FF3FF7FFC07F9KFDKF803FF807JFE,O078FF9FFBFE00FF9FI0F9FL07FF807JFE,N03FC7FCFFDE0C0FFBKF9KF80FCFC0FC003E,N03FE3FE7FE0FC0FF3KF3KF81F8FC0KFE,N01FF1FF3FE7FC0FF3KF3KF07IFE0KFC,O0FF8FF9FE7F81FF7E001F3EK0JFE0KF8,O07FC7FCFEFF81FF7KF3JFE1KF1KF,O03FE3FE7EFF8FFE7JFE7JFE3KF1F80FE,O01FF1FF3CJF1E7JFC7JFE7E001F9F007F,P0FF8FF9DIFE0E7JF07JFE7CI0F9F003F8,P07FC7FCDIFE0C,P03FE3FE1F7C31CKFCKF8007F001JFCFC03FC,P01FF1FF1E381FDKFCKFC00FF803JFCF80FF,Q0FF8FFBC380FDKFCKFC01FF807JFCF83FE,Q07FC7FBC30079KFDKFC03FFC0KFDF87F8,Q03FE3F3E7003801F801F800FC07EFC1F8I01F9FE,Q01FF1F3FF001801F001KFC0FC7E1FJ01F7F8,R0FF8F7FEI0801F001KF83F87E1FJ03IFC,R07FC77FE078003F003KF07JF1KF3IFE,R03FE27FC0FC003F003JFE0KF1KF3JF,R01FF07FC1FC003E003F03F81KF9KF3FE7F8,S0FF8FF80FC003E003E01FC3F001FCKF7F83FC,S07FCFF003I03E007E00FE7EI0FC7IFE7E01FE,S03FCFF8,S01FDFFC,T0FDIF,T07DFFC,T039FC,T01BE,U08,,::::::::::";

    
  // Obtener el resultado de getConfig
  // const config = await getConfig();

  // const logoCliente = config.logo; 

  // Uso de la función convertImageToZpl
 //  const logoClienteZpl = await convertImageToZplWithLabelary(logoCliente);
 const logoClienteZpl = "";

  zplFormat = zplFormat.replace("{{logoCliente}}", logoClienteZpl.toString().replace(
    /[a-zA-ZñÑáéíóúÁÉÍÓÚ]+/g,
    (match) => match.toUpperCase()
  ));

  zplFormat = zplFormat.replace("{{logoApp}}", logoApp.toString().replace(
    /[a-zA-ZñÑáéíóúÁÉÍÓÚ]+/g,
    (match) => match.toUpperCase()
  ));

    zplFormat = zplFormat.replace(
      "{{address}}",
      `${defaultData?.senderInfo?.senderAddress?.slice(0, 60) ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{address2}}",
      `${defaultData?.senderInfo?.senderMunicipality?.slice(0, 60) ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{city}}",
      `${defaultData?.shipping_address?.city?.name ?? "Ciudad no asignada"}`
    );
    zplFormat = zplFormat.replace(
      "{{emitter.name}}",nameToUse
    );
    zplFormat = zplFormat.replace(
      "{{emitter.phone}}",
      `${
        !!defaultData?.senderUser?.phone ? defaultData?.senderUser?.phone : ""
      }`
    );
    zplFormat = zplFormat.replace(
      "{{receiver.name}}", receiverNameToUse
    );
    zplFormat = zplFormat.replace(
      "{{receiver.phone}}",
      `${defaultData?.receiver_phone ?? "Teléfono no asignado"}`
    );

    zplFormat = zplFormat.replace(
      "{{receiver.address_line}}",
      `${defaultData?.shipping_address?.address_line?.slice(0, 50) ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{receiver.address_line2}}",
      `${defaultData?.shipping_address?.address_line?.slice(50) ?? ""} ${
        defaultData?.shipping_address?.city?.name ?? ""
      }`
    );
    zplFormat = zplFormat.replace(
      "{{receiver.comment}}",
      `${defaultData?.shipping_address?.comment ?? "Sin comentarios"}`
    );

    zplFormat = zplFormat.replace("{{packageKey}}", `${defaultData?._id}`);
    zplFormat = zplFormat.replace(
      "{{followingNumber}}",
      `${defaultData?.followingNumber}`
    );
    zplFormat = zplFormat.replace(
      "{{comments}}",
      `${defaultData?.comments ?? "Sin comentarios"}`
    );
    zplFormat = zplFormat.replace(
      "{{order_id}}",
      `${defaultData?.order_id ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{fecha}}", fechaActual
    );

    zplFormat = zplFormat.replaceAll("ñ", "n");
    zplFormat = zplFormat.replaceAll("Ñ", "N");

    const zip = new JSzip();
    zip.file(fileName("zpl"), zplFormat);
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, fileName("zip"));
    });
  };

  const downloadZplFormat = async () => {
    let zplFormat = ZplTemplate;
    const businessName = defaultData?.senderInfo?.businessName;
    const senderName = defaultData?.senderUser?.senderName ?? "";
    const senderLastName = defaultData?.senderUser?.senderLastName ?? ""; 
    const nameToUse = businessName || (senderName + " " + senderLastName);
    const receiverName = defaultData?.receiver_name ?? "";
    const receiverLastName = defaultData?.receiver_lastName ?? "";
    const receiverNameToUse = (receiverName + " " + receiverLastName)
    const fechaActual = getFormattedDate();

    zplFormat = zplFormat.replace(
      "{{address}}",
      `${defaultData?.senderInfo?.senderAddress?.slice(0, 60) ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{address2}}",
      `${defaultData?.senderInfo?.senderMunicipality?.slice(0, 60) ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{city}}",
      `${defaultData?.shipping_address?.city?.name ?? "Ciudad no asignada"}`
    );
    zplFormat = zplFormat.replace(
      "{{emitter.name}}",nameToUse
    );
    zplFormat = zplFormat.replace(
      "{{emitter.phone}}",
      `${
        !!defaultData?.senderUser?.phone ? defaultData?.senderUser?.phone : ""
      }`
    );
    zplFormat = zplFormat.replace(
      "{{receiver.name}}", receiverNameToUse
    );
    zplFormat = zplFormat.replace(
      "{{receiver.phone}}",
      `${defaultData?.receiver_phone ?? "Teléfono no asignado"}`
    );

    zplFormat = zplFormat.replace(
      "{{receiver.address_line}}",
      `${defaultData?.shipping_address?.address_line?.slice(0, 50) ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{receiver.address_line2}}",
      `${defaultData?.shipping_address?.address_line?.slice(50) ?? ""} ${
        defaultData?.shipping_address?.city?.name ?? ""
      }`
    );
    zplFormat = zplFormat.replace(
      "{{receiver.comment}}",
      `${defaultData?.shipping_address?.comment ?? "Sin comentarios"}`
    );

    zplFormat = zplFormat.replace("{{packageKey}}", `${defaultData?._id}`);
    zplFormat = zplFormat.replace(
      "{{followingNumber}}",
      `${defaultData?.followingNumber}`
    );
    zplFormat = zplFormat.replace(
      "{{comments}}",
      `${defaultData?.comments ?? "Sin comentarios"}`
    );
    zplFormat = zplFormat.replace(
      "{{order_id}}",
      `${defaultData?.order_id ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{fecha}}", fechaActual
    );

    zplFormat = zplFormat.replaceAll("ñ", "n");
    zplFormat = zplFormat.replaceAll("Ñ", "N");

    const zip = new JSzip();
    zip.file(fileName("zpl"), zplFormat);
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, fileName("zip"));
    });
  };

  const tryDownloadPdf = async () => {
    const { data, error } = await downloadPdf({
      id: user?._id,
      packages: [defaultData],
    });

    if(error) showToast("Error al descargar PDF.", false)

    if(data) {
      showToast("Se ha generado el PDF correctamente.", true)
      window.open(data?.url)
    }

  }

  const txt = () => {

    
    // const element = document.createElement('a')

    // element.setAttribute(
    //   'href',
    //   `data:text/plain;charset=utf-8,${downloadTxt(defaultData)}`
    // );
    // element.setAttribute('download', fileName("txt"));

    // element.style.display = 'none';
    // document.body.appendChild(element);

    // element.click();

    // document.body.removeChild(element);

    const element = document.createElement('a')
    const zplFinal = multipleDownloadZPLFile({packages:  [defaultData] })

    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(zplFinal)}`
    );

    
    element.setAttribute('download', fileName("txt"));

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    // setConfirmationModal(false)
  }

  return (
    <>
      <div className="p-4">
        <Label className="forms-following-number-title mt-3 mb-0">
          Pedido {defaultData.type === 'MercadoLibre' && 'MercadoLibre'} {defaultData.followingNumber}
        </Label>
        {/* <div id="myDiv">
          
        </div> */}
        <FormsDivider />
        <Row>
          <Col xs="12" md="12" xl="12">
            <Label className="forms-input-title">NOMBRE COMPLETO</Label>
            <Input
                type="text"
                className="p-2 px-3 forms-custom-input"
                placeholder="Nombres y Apellidos"
                disabled
                defaultValue={`${defaultData?.receiver_name || ''} ${defaultData?.receiver_lastName || ''}`}
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">TELÉFONO</Label>
            <Input
              maxLength={9}
              type="receiver_phone"
              className="p-2 px-3 forms-custom-input"
              placeholder="Ej: 987654321"
              disabled
              defaultValue={defaultData?.receiver_phone}
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">COMUNA DE ENTREGA</Label>
            <SearchDropdown
              options={posibleComs}
              placeholder="Buscar comuna"
              selected={defaultData?.shipping_address?.city?.name}
              disabled
            />
          </Col>
          <Col xs="12" md="12" xl="12" className="mt-3">
            <Label className="forms-input-title">DIRECCIÓN ENTREGA</Label>
            <Input
              type="street_name"
              className="p-2 px-3 forms-custom-input"
              placeholder="Ej: Moneda"
              disabled
              defaultValue={defaultData?.shipping_address?.address_line}
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">
              VILLA / POBLACIÓN DE ENTREGA
            </Label>
            <Input
              type="neighborhoodName"
              className="p-2 px-3 forms-custom-input"
              placeholder="Ej: Los heroes"
              disabled
              defaultValue={defaultData?.shipping_address?.neighborhood?.name}
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">NÚMERO DE VENTA</Label>
            <Input
              type="order_id"
              className="p-2 px-3 forms-custom-input"
              placeholder="Ej: 659"
              disabled
              defaultValue={defaultData?.order_id}
            />
          </Col>
          <Col xs="12" md="12" xl="12" className="mt-3 mb-3">
            <Label className="forms-input-title">COMENTARIO</Label>
            <Input              type="comment"
              className="p-2 px-3 forms-custom-input-full-width"
              placeholder="Ej: Dejar paquete en conserjería"
              disabled
              defaultValue={defaultData?.shipping_address?.comment}
            />
          </Col>
          {
            defaultData.type === 'MercadoLibre' &&
          <Col xs="12" md="12" xl="12" className="mt-3 mb-3">
            <strong>
              <p>
                Etiqueta MercadoLibre
              </p>
            </strong>
            <QRCode value={defaultData?.qr} />
          </Col>
          }
        </Row>
      </div>
      {
        defaultData.type !== 'MercadoLibre' ?
        <div>
          <Row className="px-3">
            <Col xs="6" md="6" xl="6">
              <Button block className="bg-black" color="dark" onClick={() => downloadZplFormatNew()}>
                Descargar etiqueta Zebra aca (.zpl)
              </Button>
            </Col>
            <Col xs="6" md="6" xl="6">
              <Button className="bg-black" block onClick={() => tryDownloadPdf()}>
                Descargar etiqueta PDF (.pdf)
              </Button>
            </Col>

          </Row>
          <div className="px-3 mt-1">
            <Button className="custom-white-btn px-3" block onClick={() => txt()}>
              Descargar etiqueta TXT  aca (.txt)
            </Button>
          </div>
        </div>
            : <div></div>
      }

    </>
  );
}
