import Axios from "../config/axios-v1-config";
import AxiosV2 from "../config/axios-v2-config";

export const getRoutesApi = async () => {
    const clients = await Axios.get("route").catch((err) => {
      return { error: err.response.data };
    });
  
    return clients;
  };

export const getRoute = async (routeId) => {
  const clients = await Axios.get("route/" + routeId).catch((err) => {
    return { error: err.response.data };
  });

  return clients;
};

export const getRoutesWithDetails = async () => {
  const clients = await Axios.get("routes/routesWithDetails").catch((err) => {
    return { error: err.response.data };
  });

  //console.log("clients: ", clients);
  return clients.data;
};


export const addRouteApi = async (newRoute) => {
  try {
    const response = await Axios.post("routes", newRoute);
    return response.data; // Retornar los datos de la respuesta en caso de éxito
  } catch (error) {
    console.error("Error al crear la ruta:", error);
    return { error: error.response ? error.response.data : error.message };
  }
};

export const addToRouteApi = async (routeId, clientsId) => {
  await Axios.post("addToRoute", {id: routeId, clientsId}).catch((err) => {
      return { error: err.response.data };
      });
  }


export const deleteRoutesApi = async (routeId) => {
  try {
    const response = await Axios.delete("route", {
      data: { id: routeId },
    });
    return response.data;
  } catch (err) {
    return { error: err.response.data };
  }
};

export const deleteClientFromRouteApi = async (routeId, clientId) => {
  await Axios.post("deleteClient", {id: routeId, clientId}).catch((err) => {
      return { error: err.response.data };
      });
  }

export const assignDeliveryApi = async (routeId, deliveryId) => {
  await Axios.patch(`routes/${routeId}`, {data: {deliveryMan: deliveryId}}).catch((err) => {
    return { error: err.response.data };
  });
}

export const updateRoute = async (routeId, updatedRouteData) => {
  await Axios.patch(`routes/${routeId}`, { data: updatedRouteData }).catch((err) => {
    return { error: err.response.data };
  });
};            


export const getDeliveredPackages = async (date) => {
  const formattedDate = new Date(date).toISOString().split('T')[0];
  const {data} = await Axios.get(`routes/packagesGroupedByDeliverer?date=${formattedDate}`);

  return data.data
}