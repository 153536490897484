//src/api/integrations.js
import Axios from "../config/axios-v1-config";

export const getUserIntegrations = async (values) => {
  try {
    const response = await Axios.post("integrations/verify", values);
    return response.data;
  } catch (err) {
    return { error: err.response.data };
  }
};

export const getMeliAuthURL = async (values) => {
  try {
    const response = await Axios.post("integrations/mercadolibre/auth-url", values);
    return response.data;
  } catch (err) {
    return { error: err.response.data };
  }
};

export const addShopify = async (values) => {
  return await Axios.post(
      "integrations/shopify/add-integration",
      values
  ).catch((err) => {
    return {error: err.response.data};
  });
};


// Función para integrar MercadoLibre (flex-token)
export const integrateMercadoLibre = async (values) => {
  try {
    const response = await Axios.post("integrations/mercadolibre/generate-token", values);
    return response.data;
  } catch (err) {
    return { error: err.response?.data || "Error desconocido" };
  }
};


export const addFalabella = async (values) => {
  return await Axios.post("integrations/falabella/add-integration", values).catch(
      (err) => {
        return {error: err.response.data};
      }
  );
};

export const changeAliasApi = async (values) => {
  const response = await Axios.patch("integration", values).catch((err) => {
    return { error: err.response.data };
  });

  return response;
};

export const addWoocommerce = async (values) => {
  return await Axios.post(
      "integrations/woocommerce/add-integration",
      values
  ).catch((err) => {
    return {error: err.response.data};
  });
};

export const addSendu = async (values) => {
  return await Axios.post("integrations/sendu/add-integration", values).catch(
      (err) => {
        return {error: err.response.data};
      }
  );
};