import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { Routes, Route, useNavigate } from "react-router-dom";
import AuthProvider from "../../providers/authProviders";
import useAuth from "../../hooks/useAuth";

// Mapea los ObjectId de roles con sus rutas correspondientes
const roleRoutes = {
  admin: "66edc1f613059690d19d6ea5",
  client: "66edc2fc13059690d19d6ead", // KAM o AM dependiendo del negocio
  delivery: "66edc29a13059690d19d6ea9",
  supervisor: "66edc25a13059690d19d6ea7",
  support: "66edc2c613059690d19d6eab",
  superadmin: "66edc32f13059690d19d6eb1", // Super administrador
};

export default function LoginLayout({ routes }) {
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  //console.log("usuario: ", user)
  useEffect(() => {
    if (user && !isLoading) {
      //console.log("navigatin")
      switch (user.role) {
        case roleRoutes.admin:
          navigate("/admin");
          break;
        case roleRoutes.client:
          navigate("/home");
          break;
        case roleRoutes.delivery:
          navigate("/delivery");
          break;
        case roleRoutes.supervisor:
          navigate("/supervisor");
          break;
        case roleRoutes.support:
          navigate("/support");
          break;
        case roleRoutes.superadmin:
          navigate("/superadmin");
          break;
        default:
          // Si el rol no coincide con ninguno de los mapeados, redirigir a una página genérica o de error
          navigate("/login");
      }
    }
  }, [user, navigate, isLoading]);

  useEffect(() => {
    if (!user && !isLoading && !window.location.pathname.startsWith("/login")) {
      navigate("/login");
    }
  }, [user, isLoading, navigate]);

  return (
    <Container
      className="w-100 m-0 p-0"
      style={{ maxWidth: "100%", backgroundColor: "none" }}
    >
      <ToastContainer />
      <Row>
        <Col className="w-100 vh-100">
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <AuthProvider>
                    <route.element />
                  </AuthProvider>
                }
              />
            ))}
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}
