import React, { useState } from "react";
import { Container, Button, Label, Col, Spinner } from "reactstrap";
import Select from "react-select";
import useAuth from "../../hooks/useAuth";
import { loadFile } from "../../api/packages";
import { toast } from "react-toastify";
import FileInput from "../fileInput";

import Template from "../../template/template.xlsx";

export default function LoadFile({
  closeModal,
  fetch,
  setConfirmationModal,
  setPackagesCreated,
}) {
  const [name, setName] = useState();
  const [fileData, setFileData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  // 📥 Get user data from auth hook
  const { userData } = useAuth();

  // 📂 Handle file selection
  const onSelectDocument = (file) => {
    setName(file[0].name);
    setFileData(file[0]);
  };

  // 📤 Handle form submission
  const onSendForm = async () => {
    if (!selectedAddress) {
      toast.error("Por favor, selecciona una dirección de retiro.");
      return;
    }

    setLoading(true);
    const reader = new FileReader();
    reader.onload = async function (evt) {
      const parsedDocument = evt.target.result;

      try {
        const { data, error } = await loadFile({
          file: parsedDocument,
          user: userData,
          senderAddress: selectedAddress.value.address, // 🆕 Include selected address
        });

        if (data) {
          closeModal(false);
          setConfirmationModal(true);
          setPackagesCreated(data?.packages);
          fetch(true);
        }

        if (error?.uncompletedCells) {
          setLoading(false);
          toast.info(
            `Faltan por completar los siguientes campos: ${error.uncompletedCells.join(
              ", "
            )}`,
            { autoClose: 10000 }
          );
          return;
        }

        if (error) {
          toast.error("Error al cargar los paquetes.");
          closeModal(false);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    reader.readAsBinaryString(fileData);
  };

  console.log({ userData });

  return (
    <Container className="mt-3 mb-3 w-100 d-grid justify-content-center">
      {/* 🏠 DIRECCIÓN DE RETIRO */}
      <Col xs="12" md="12" xl="12" className="my-3">
        <Label className="forms-input-title">DIRECCIÓN DE RETIRO</Label>
        <Select
          options={userData?.companyId?.shippingAddresses.map((address) => ({
            value: address._id,
            label:
              `${address.address}, ${address.municipality}, ${address.state}`.toUpperCase(),
          }))}
          placeholder="Selecciona la dirección de retiro"
          value={selectedAddress}
          onChange={setSelectedAddress}
        />
      </Col>

      {/* 📁 File Input */}
      <div className="flex justify-center">
        <FileInput onSelectDocument={onSelectDocument} />
      </div>
      {/* 📤 Send Button */}
      <div className="d-flex justify-content-center mt-10">
        <Button
          color="dark"
          className="custom-modal-btn bg-black"
          disabled={!name || loading}
          onClick={() => onSendForm()}
        >
          {name || "Sin documento"}
        </Button>
      </div>

      {/* 🔽 Mensaje/Spinner de "Cargando" */}
      {loading && (
        <div className="d-flex flex-column align-items-center mt-3">
          <Spinner color="primary" />
          <p className="mt-2">Subiendo información a nuestro servidor...</p>
        </div>
      )}

      <a
        href={Template}
        className="custom-link-modal"
        download={"template.xlsx"}
      >
        Descargar plantilla
      </a>
    </Container>
  );
}
