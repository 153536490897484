import axios from "axios";
import { AuthService } from "services/authservice";

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_CONNECTION}`,
  timeout: 30000,
  mode: "no-cors",
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

Axios.defaults.headers.common["content-type"] = "application/json";
Axios.defaults.headers.common["application"] = "web";
Axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("accessToken")}`;

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    if (error.response && error.response.status === 401) {
      AuthService.logout();
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export default Axios;