import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Label,
  Input,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getDeliveryUsers } from "../../../api/user";
import { getActiveClientsApi } from "../../../api/company";
import { generateInformApi, generateCollectReportApi } from "../../../api/inform";
import { iconList } from "../../../utils/iconList";
import SearchDropdown from "../../../components/searchDropdown";
import moment from "moment/moment";
import useAuth from "../../../hooks/useAuth";

export default function CreateInforms() {
  const navigate = useNavigate();
  const [informType, setInformType] = useState();
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [clientList, setClientList] = useState([]);
  const [clientSelected, setClientSelected] = useState();
  const [deliveryList, setDeliveryList] = useState([]);
  const [deliverySelected, setDeliverySelected] = useState();
  const [loading, setLoading] = useState(false);

  const informTypes = [
    { value: "all", label: "Consolidado" },
    { value: "client", label: "Por cliente" },
    { value: "delivery", label: "Por repartidor" },
    { value: "collect", label: "Colecta" },
  ];

  const selectedType = informTypes?.find((f) => f.value === informType);
  const { user } = useAuth();
  const logisticCompanyId = user.logisticCompany._id || null;
  const userDeliveryRole = "delivery";
  const status = "active";
  
  useEffect(() => {
    console.log("usuario: ", user)
    getActiveClientsApi(logisticCompanyId)
      .then((data) => {
        const parsedData = data?.map((client) => ({
          label: client?.name,
          value: client?._id,
        }));
        setClientList(parsedData);
      })
      .catch((ex) => console.error(ex));

      if (user && logisticCompanyId) {
        getDeliveryUsers(status,userDeliveryRole,logisticCompanyId)
        .then((data) => {
          const parsedData = data?.docs?.map((user) => ({
            label: `${user?.name} ${user?.lastName}`,
            value: user?._id,
          }));
          setDeliveryList(parsedData);
        })
        .catch((ex) => console.error(ex));
      }
    }, [user, logisticCompanyId]);

  const client = clientList?.find((f) => f.value === clientSelected);
  const delivery = deliveryList?.find((f) => f.value === deliverySelected);

  const isDisabled = !informType
  ? true
  : informType === "all" &&
    (!selectedDate?.endDate || !selectedDate?.startDate)
  ? true
  : (informType === "client" && !clientSelected) ||
    !selectedDate?.endDate ||
    !selectedDate?.startDate
  ? true
  : (informType === "delivery" && !deliverySelected) ||
    !selectedDate?.endDate ||
    !selectedDate?.startDate
  ? true
  : informType === "collect" &&
    (!selectedDate?.endDate || !selectedDate?.startDate)
  ? true
  : false;


    const generateInform = () => {
      const values =
        informType === "all"
          ? { startDate: selectedDate?.startDate, endDate: selectedDate?.endDate }
          : informType === "client"
          ? {
              startDate: selectedDate?.startDate,
              endDate: selectedDate?.endDate,
              clientId: clientSelected,
            }
          : informType === "delivery"
          ? {
              startDate: selectedDate?.startDate,
              endDate: selectedDate?.endDate,
              deliveryId: deliverySelected,
            }
          : informType === "collect" // Lógica para Colecta
          ? {
              fecha_desde: selectedDate?.startDate,
              fecha_hasta: selectedDate?.endDate,
              formatoSalida: "1", // Puedes ajustar este valor según sea necesario
              token: user?.token, // Asegúrate de incluir el token
            }
          : null;
    
      if (!values) return;
    
      setLoading(true);
      if (informType === "collect") {
        generateCollectReportApi(values).then(() => setLoading(false));
      } else {
        generateInformApi(values, informType).then(() => setLoading(false));
      }
    };

  return (
      // se cambia componente section por div con estilos segun standar - ksandoval - 10-05
    <div className="w-100 all-orders-container mt-6">
      {/* <SearchBar
        message={"Buscar por número de orden, destino o quién recibe"}
      /> */}
      <Breadcrumb className="custom-breadcrumb-container">
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate("/admin")}
        >
          <img src={iconList.home} alt="home" className="mr-2" />
          <span>Inicio</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Informes</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Crear informe</span>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <span className="w-100 d-flex justify-content-between">
          <h2 className="h2-custom-size all-users-container__title">
            Informes
          </h2>
        </span>
      </div>
      <Card className="custom-inform-card">
        <Label className="mb-2 custom-label-inform">Tipo de informe</Label>
        <SearchDropdown
          placeholder="Selecciona el tipo de reporte"
          options={informTypes}
          onChange={setInformType}
          selected={selectedType?.label}
        />
        <div className="d-flex justify-content-between mt-4">
          <Col xs="6" md="6" xl="6" className="pr-3">
            <Label className="mb-2 custom-label-inform">Fecha</Label>
            <Input
              type="date"
              max={moment(selectedDate?.endDate)
                .subtract(1, "days")
                .format("YYYY-MM-DD")}
              onChange={(e) =>
                setSelectedDate({
                  ...selectedDate,
                  startDate: moment(e.target.value).format("MM/DD/YYYY"),
                })
              }
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="pl-3">
            <Label className="mb-2 custom-label-inform">Hasta</Label>
            <Input
              type="date"
              min={moment(selectedDate?.startDate)
                .add(1, "days")
                .format("YYYY-MM-DD")}
              onChange={(e) =>
                setSelectedDate({
                  ...selectedDate,
                  endDate: moment(e.target.value).format("MM/DD/YYYY"),
                })
              }
            />
          </Col>
        </div>
        {informType === "client" && (
          <div className="mt-4">
            <Label className="mb-2 custom-label-inform">Cliente</Label>
            <SearchDropdown
              placeholder="Seleccione cliente."
              options={clientList}
              onChange={setClientSelected}
              selected={client?.label}
              tooltip
            />
          </div>
        )}
        {informType === "delivery" && (
          <div className="mt-4">
            <Label className="mb-2 custom-label-inform">Repartidor</Label>
            <SearchDropdown
              placeholder="Seleccione repartidor."
              options={deliveryList}
              onChange={setDeliverySelected}
              selected={delivery?.label}
              tooltip
            />
          </div>
        )}
        <div className="w-100 d-flex align-items-center">
          <Button
            color="dark"
            className="mt-4 custom-inform-button bg-black"
            disabled={isDisabled || loading}
            onClick={() => generateInform()}
          >
            Descargar informe
          </Button>
          {loading && <Spinner className="ml-3 mt-4" />}
        </div>
      </Card>
    </div>
  );
}
