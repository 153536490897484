import Axios from "../config/axios-v1-config";

// Obtener todas las empresas
export const getAllCompanies = async () => {
  const companies = await Axios.get("companies").catch((err) => {
    return { error: err.response.data };
  });

  return companies;
};

// Obtener una empresa por ID
export const getCompany = async (id) => {
  try {
    const response = await Axios.get(`companies/${id}`);
    return response.data; // Retornamos directamente el resultado
  } catch (err) {
    console.error('Error al obtener la compañía:', err);
    return { error: err.response.data };
  }
};

// Crear una nueva empresa
export const createCompany = async (data) => {
  const newCompany = await Axios.post("companies", data).catch((err) => {
    return { error: err.response.data };
  });

  return newCompany;
};

// Actualizar una empresa existente
export const updateCompany = async (id, data) => {
  const updatedCompany = await Axios.put(`companies/${id}`, data).catch((err) => {
    return { error: err.response.data };
  });

  return updatedCompany;
};

// Eliminar una empresa
export const deleteCompany = async (id) => {
  const deletedCompany = await Axios.delete(`companies/${id}`).catch((err) => {
    return { error: err.response.data };
  });

  return deletedCompany;
};

// Obtener empresas con paginación y búsqueda
export const getCompaniesPaginated = async (page = 1, limit = 10, searchTerm = "") => {
  let url = `companies-paginated?page=${page}&limit=${limit}`;

  if (searchTerm !== "") {
    url += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }

  const companies = await Axios.get(url).catch((err) => {
    return { error: err.response.data };
  });

  return companies;
};

export const getActiveClientsApi = async (logisticCompanyId) => {
  try {
    const response = await Axios.post('users/companies/active-contracts', {
      logisticCompanyId,
    }); 

    return response.data; 
  } catch (err) {
    if (err.response && err.response.data) {
      return { error: err.response.data };
    }
    return { error: 'Ocurrió un error al obtener las compañías.' };
  }
};
