import { useState } from "react";
import useRoutes from "../../store/routes";
import { InputGroup, Button } from "reactstrap";
import Search from "../../assets/search.svg";

export function FullInput({ placeholder, setSearchTerm }) {
  const setClientQuery = useRoutes((state) => state.setClientQuery);
  const [searchValue, setSearchValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      //console.log("el valor buscado con enter: ",searchValue)
      setClientQuery(searchValue);
    }
  };

  const handleSearch = () => {
    //console.log("el valor buscado con boton: ",searchValue)
    setClientQuery(searchValue);
  };

  return (
    <InputGroup>
    <input
      type="text"
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      onKeyDown={handleKeyDown}
      className="min-w-fit w-[90%] p-2 rounded-lg text-md border px-3 py-[7px] border-gray-200 outline-none"
    />
     <Button color="primary" onClick={handleSearch}>
          <img src={Search} alt="Search" />
        </Button>
   </InputGroup>
  );
}
