export const roles = [
    {label: "Repartidor", value: "delivery"},
    {label: "Cliente", value: "client"},
    {label: "Administrador", value: "admin"},
]

export const roleRoutes = {
    admin: "66edc1f613059690d19d6ea5",
    client: "66edc2fc13059690d19d6ead", // KAM o AM dependiendo del negocio
    delivery: "66edc29a13059690d19d6ea9",
    supervisor: "66edc25a13059690d19d6ea7",
    support: "66edc2c613059690d19d6eab",
    superadmin: "66edc32f13059690d19d6eb1", // Super administrador
  };