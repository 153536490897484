import {create} from 'zustand';
import Axios from '../config/axios-v1-config';
import getNextWeeks from '../utils/weeks';
import {getDeliveriesListed,getDeliveryUsers} from "../api/user"
import {getActiveClientsApi } from "../api/company"
import {getRoutesApi, getRoutesWithDetails} from "../api/routes"

export default create((set) => ({

    
    clients: [],
    selectedClients: [],
    weeks: getNextWeeks(),
    selectedWeek: 0,
    routes: [],
    clientQuery: "",
    selectedRoutes: [],
    deliveries: [],
    fetchDeliveries: async(status, userDeliveryRole, logisticCompanyId) => {
        try {
            let deliveries = await getDeliveryUsers(status, userDeliveryRole, logisticCompanyId)
            //console.log("in routes fetchDeliveries::::", deliveries)
            deliveries = deliveries.docs
            set((state) => ({...state, deliveries}))
        } catch (error) {
            console.log(error)
        }
    },
    setClientQuery: (query) => {
        set((state) => ({...state, clientQuery: query}))
    },
    fetchRoutes: async() => {
        try {
            let routes = await getRoutesApi()
            routes = routes.data.data
            set((state) => ({...state, routes}))
        } catch (error) {
            console.log(error)
        }
    },
    fetchRoutesBeartrack: async() => {
        try {
            let routes = await getRoutesWithDetails()
            routes = routes.data
            set((state) => ({...state, routes}))
        } catch (error) {
            console.log(error)
        }
    },
    fetchClients: async(logisticCompanyId) => {
        try {
            let activeClients = await getActiveClientsApi(logisticCompanyId)
        set((state) => ({...state, clients: activeClients }))
        } catch (error) {
            console.log(error)
        }
        
    },
    toggleClient: (id, isChecked) =>{
        set((state) => {
            if(isChecked){
                return {...state, selectedClients: [...state.selectedClients, id]}
            }else{
                return {...state, selectedClients: state.selectedClients.filter(clientId => clientId !== id)}
            }
        })
        
    },
    toggleRoute: (id, isChecked) =>
        set((state) => {
            if(isChecked){
                return {...state, selectedRoutes: [...state.selectedRoutes, id]}
            }else{
                return {...state, selectedRoutes: state.selectedRoutes.filter(routeId => routeId !== id)}
            }
        }),
    selectAll: () => {
        set((state) => {
            if(state.selectedClients.length === state.clients.length){
                return {...state, selectedClients: []}
            }
            return {...state, selectedClients: state.clients.map(client => client._id)}
        })
    },
    sortClientsBySelected: () => {
        set((state) => {
            const { selectedClients, clients } = state;
            const sortedClients = [...clients];
            selectedClients.forEach((idSeleccionado) => {
              const index = sortedClients.findIndex(
                (cliente) => cliente._id === idSeleccionado
              );
              if (index !== -1) {
                const clienteSeleccionado = sortedClients.splice(index, 1)[0];
                sortedClients.unshift(clienteSeleccionado);
              }
            });
            // console.log(sortedClients)
            return { clients: sortedClients };
          });
    },
    deselectAllClients: () => {
        set((state) => {
            return {...state, selectedClients: []}
        })
    },
    deselectAllRoutes: () => {
        set((state) => {
            return {...state, selectedRoutes: []}
        })
    },
    changeClientPreferedHour: async (clientId, newHour) => {
        await Axios.put("setPreferedHour", {
            id: clientId,
            preferedHour: newHour
        })
        set((state) => {
            
            return {...state, clients: state.clients.map(client => {
                if(client._id === clientId){
                    return {...client, preferedHour: newHour}
                }else{
                    return client
                }
            })}
        })
    },
    setWeek: (week) => {
        set((state) => {
            return {...state, selectedWeek: week}
        })
    }
  }))