import React from "react";
import { iconList } from "../../utils/iconList";

export default function Stepper({ packageData }) {
  //console.log("packageData:", packageData);
  if (!packageData) {
    return null; // O muestra un indicador de carga
  }

  // Definir el estado actual y el historial de estados
  let currentStatus = packageData?.status ?? -1;
  let statusHistory = Array.isArray(packageData?.packageStatusHistory) ? packageData.packageStatusHistory : [];

  // Definir el orden de los estados
  const statusOrder = [0, 1, 2, 3, 30, 4, 5, 6, 7];

  // Función para obtener la posición de un código de estado en 'statusOrder'
  const getStatusPosition = (statusCode) => {
    const index = statusOrder.indexOf(statusCode);
    return index >= 0 ? index : -1;
  };

  // Establecer el estado actual
  if (statusHistory.length > 0) {
    currentStatus = statusHistory[statusHistory.length - 1].status;
  } else {
    // Estructura antigua: reconstruimos el historial a partir de las claves de estado
    const statusKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '30'];
    statusKeys.forEach((key) => {
      if (packageData[key] && Array.isArray(packageData[key]) && packageData[key].length > 0) {
        statusHistory.push({ status: parseInt(key), updatedAt: packageData[key][0]?.updatedAt });
      }
    });
    // Ordenamos el historial por código de estado
    statusHistory.sort((a, b) => getStatusPosition(a.status) - getStatusPosition(b.status));
    // Establecemos el estado actual al código de estado más alto
    if (statusHistory.length > 0) {
      currentStatus = statusHistory[statusHistory.length -1].status;
    }
  }

  // Definimos los pasos y sus códigos de estado correspondientes
  const steps = [
    { label: "Paquete ingresado", statusCodes: [0] },
    { label: "Paquete recolectado", statusCodes: [1, 2] },
    { label: "Paquete en ruta", statusCodes: [3] },
    { label: "Paquete entregado", statusCodes: [4] },
    { label: "Cancelado por el vendedor", statusCodes: [5] },
    { label: "Dirección incorrecta o incompleta", statusCodes: [6] },
    { label: "Sin moradores", statusCodes: [7] },
  ];

  // Función para determinar la clase CSS del paso
  const getStepClass = (stepIndex) => {
    const stepStatusCodes = steps[stepIndex].statusCodes;
    const stepPosition = getStatusPosition(stepStatusCodes[0]);
    const currentPosition = getStatusPosition(currentStatus);

    if (currentPosition === -1) {
      // Estado desconocido
      return "pending-rounded";
    }

    if (currentPosition === stepPosition) {
      return "process-rounded";
    } else if (currentPosition > stepPosition) {
      return "complete-rounded";
    } else {
      return "pending-rounded";
    }
  };

  // Función para renderizar el ícono dentro del círculo del paso
  const renderStepIcon = (stepIndex) => {
    const stepStatusCodes = steps[stepIndex].statusCodes;
    const stepPosition = getStatusPosition(stepStatusCodes[0]);
    const currentPosition = getStatusPosition(currentStatus);

    if (currentPosition === -1) {
      // Estado desconocido
      return <div className="rounded-circle rounded-pending-process" />;
    }

    if (currentPosition === stepPosition) {
      return <div className="rounded-circle bg-primary rounded-process" />;
    } else if (currentPosition > stepPosition) {
      return <img src={iconList.check} alt="img" />;
    } else {
      return <div className="rounded-circle rounded-pending-process" />;
    }
  };

  return (
    <div className="p-4 d-flex align-items-center">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div
            className={`rounded-circle ${getStepClass(index)} d-flex justify-content-center align-items-center`}
          >
            {renderStepIcon(index)}
          </div>
          <div className="d-grid ml-3">
            <span className="custom-status-following">{step.label}</span>
            {/* Opcionalmente, puedes mostrar la fecha */}
            {/*<span className="custom-date-following">Fecha</span>*/}
          </div>
          {index < steps.length -1 && <div className="line-flow ml-1"></div>}
        </React.Fragment>
      ))}
    </div>
  );
}
