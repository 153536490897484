import Axios from "../config/axios-v1-config";

export const getActiveFaqs = async () => {
  const faqs = await Axios.get("faqs/get-active-faqs").catch((err) => {
    return { error: err.response.data };
  });

  return faqs;
};

export const getAllFaqs = async () => {
  const faqs = await Axios.get("faqs/get-all-faqs").catch((err) => {
    return { error: err.response.data };
  });

  return faqs;
};

export const changeFaqStatus = async ({ id, status }) => {
  const faqs = await Axios.put(`faqs/status-faq/${id}`, { status }).catch((err) => {
    return { error: err.response.data };
  });

  return faqs;
};

export const editFaq = async ({ values }) => {
  // console.log(values);
  const editedFaq = await Axios.put(`faqs/edit-faq/${values.id}`, { ...values }).catch((err) => {
    return { error: err.response.data };
  });

  return editedFaq;
};

export const createFaq = async ({ values }) => {
  const faqs = await Axios.post("faqs/create-faq", { ...values }).catch((err) => {
    return { error: err.response.data };
  });

  return faqs;
};

export const deleteFaqApi = async ({ id }) => {
  const faq = await Axios.delete(`faqs/delete-faq/${id}`).catch((err) => {
    return { error: err.response.data };
  });

  return faq;
};
