import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { deleteUserApi } from "../../api/user";
import { activateUserApi } from "../../api/user";
import { toast } from "react-toastify";
import { deleteRoutesApi } from "../../api/routes";


export default function BlockDeleteUserBeartrack({
  block,
  closeModal,
    cerrarModal,
  fetch,
  route,
    user,
  isRoute, // Nuevo parámetro para determinar si es ruta o usuario
}) {
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const methods = useForm({
    mode: "onSubmit",
    defaultValues: block ? route : {},
  });

  useEffect(() => {
    if (block) {
      methods.setValue("pass", route?.pass || "beartrack2024");
    }
  }, [block, route, methods]);

  const ErrorMessage = ({ msg }) => (
    <FormFeedback className="d-block">{msg}</FormFeedback>
  );

  const FormDivider = ({ className }) => (
    <div className={`w-full border-t border-gray-300 my-2 ${className}`} />
  );

  const submitValues = useCallback(
    async (values) => {
      setLoading(true);

      try {
        if (block) {
          // await blockUserApi(route._id);
        } else {
          console.log("submitValues_eliminar route:::", route._id);
        }
        setLoading(false);
        closeModal(false);
        fetch(true);
      } catch (error) {
        console.error("Error al guardar los cambios:", error);
        setLoading(false);
      }
    },
    [closeModal, block, fetch, route]
  );

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleDelete = useCallback(() => {
    if (isChecked) {
      const toastId = toast.loading("Procesando...");

      if (block) {
        //console.log("bloquear user:::", user._id);
        setLoading(true);
        activateUserApi({ userId: user._id, status: false })
          .then((data) => {
            setLoading(false);
            closeModal(false);
             cerrarModal(false);
            toast.update(toastId, {
              render: "Usuario bloqueado exitosamente",
              type: "success",
              isLoading: false,
              autoClose: 2000,
              position: "bottom-center",
            });
          })
          .catch((error) => {
            setLoading(false);
            toast.update(toastId, {
              render: "Error al bloquear el usuario",
              type: "error",
              isLoading: false,
              autoClose: 2000,
              position: "bottom-center",
            });
          });
          fetch(true)
      }
      else if (isRoute) {
        //console.log("rutas:::", route.id);
        setLoading(true);
        // deleteRoutesApi({ routeIds: route.id })
        deleteRoutesApi(route.id)
          .then((data) => {
            setLoading(false);
            closeModal(false);
            cerrarModal(false);
            toast.update(toastId, {
              render: "Ruta eliminada exitosamente",
              type: "success",
              isLoading: false,
              autoClose: 2000,
              position: "bottom-center",
            });
          })
          .catch((error) => {
            setLoading(false);
            toast.update(toastId, {
              render: "Error al eliminar la ruta",
              type: "error",
              isLoading: false,
              autoClose: 2000,
              position: "bottom-center",
            });
          });
          fetch(true)
      }
      else {
        //console.log("eliminar user:::", user._id);
        setLoading(true);
        deleteUserApi({ userId: user._id })
          .then((data) => {
            setLoading(false);
            closeModal(false);
            cerrarModal(false);
            toast.update(toastId, {
              render: "Usuario eliminado exitosamente",
              type: "success",
              isLoading: false,
              autoClose: 2000,
              position: "bottom-center",
            });
          })
          .catch((error) => {
            setLoading(false);
            toast.update(toastId, {
              render: "Error al eliminar el usuario",
              type: "error",
              isLoading: false,
              autoClose: 2000,
              position: "bottom-center",
            });
          });
          fetch(true)
      }

    
    }
  }, [isChecked, fetch]);

  return (
    <div>
      <h1 className="text-black font-semibold text-xl mb-0 mt-0">
        {isRoute
          ? "Eliminar ruta"
          : block
          ? "Bloquear usuario"
          : "Eliminar usuario"}
      </h1>
      <FormDivider />
      <Container className="mt-0">
        <Form onSubmit={methods.handleSubmit(submitValues)}>
          <p>
            {isRoute
              ? "Esta acción no se puede deshacer. Esto eliminará la ruta y todos los clientes que tenía asignada quedarán disponibles para nueva asignación."
              : "Esta acción no se puede deshacer. Esto eliminará permanentemente la cuenta de usuario. Todos los datos serán eliminados y no tendrá acceso a la plataforma."}
          </p>
          <FormDivider className="mb-0"></FormDivider>
          <p className="font-semibold text-[#E11900]">
            {isRoute ? `Repartidor asignado: ${route?.deliveryManName}` : route?.deliveryManName}
          </p>
          <FormDivider className="mb-0"></FormDivider>
          <Row className="mt-0">
            <Col xs="12" md="12" xl="12">
              <p>Por favor, selecciona la casilla para confirmar.</p>

              <FormGroup check className="mt-3">
                <Input
                  type="checkbox"
                  id="confirmCheckbox"
                  onChange={handleCheckboxChange}
                />
                <Label for="confirmCheckbox" check>
                  Sí, entiendo que es una acción irreversible.
                </Label>
              </FormGroup>
            </Col>
            <FormDivider></FormDivider>
          </Row>
          <Row className="mt-4">
            <Col xs="12">
              <Button
                color="orange"
                className="w-100 bg-[#D92D20] text-white hover:bg-[#B42318]"
                type="button"
                disabled={!isChecked || loading}
                onClick={handleDelete}
              >
                 {isRoute ? "Eliminar ruta" : block ? "Bloquear usuario" : "Borrar usuario"}
                 {loading && <Spinner size="sm" />}
              </Button>
            </Col>
            <Col xs="12" className="mt-2">
              <Button
                color="white"
                className="w-100 text-black border-[#D0D5DD] hover:bg-[#D0D5DD]"
                onClick={() => {closeModal(false); cerrarModal(false)}}
              >
                No, mantener
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}
