import React, { useEffect, useState } from "react";
import { updateRoute } from "../../api/routes";
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "lucide-react";
import useRoutes from "../../store/routes";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import useShippingAddressStore from "store/shippingAddress";

const ClientList = ({
  title,
  clients,
  selectedClients,
  handleCheckboxChange,
}) => {
  const [query, setQuery] = useState("");
  const { selectedShippingAddresses, setShippingAddress } =
    useShippingAddressStore();

  console.log({ clients });

  // Filter clients based on the query
  const filteredClients =
    query !== ""
      ? clients.filter(
          (client) =>
            (client.legalName ?? client.name)?.toLowerCase().includes(query) ||
            client.address?.toLowerCase().includes(query)
        )
      : clients;

  // Handle selecting a shipping address

  return (
    <div className="w-[45%] rounded-lg p-2 border border-gray-400 h-full">
      <h3 className="text-xl font-semibold whitespace-nowrap">
        {title}
        <span className="text-sm px-2 font-light text-orange-800 bg-orange-200 border-2 border-orange-500 rounded-full">
          {clients.length}
        </span>
      </h3>
      <input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="border font-light placeholder:font-light w-full mt-1 rounded-lg px-3 py-2 border-gray-400"
        type="text"
        placeholder="Nombre o direccion"
      />
      <ul className="flex flex-col overflow-x-hidden overflow-y-auto max-h-[87%]">
        {filteredClients.map((client) => {
          const displayName = client?.legalName || client?.name || "";
          return (
            <li
              key={client._id}
              className="flex flex-col w-full px-2 items-start py-1 border-b-2"
            >
              {/* Checkbox to select client */}
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  disabled={
                    client.shippingAddresses?.filter((a) => !!a.address) == 0
                  }
                  checked={selectedClients.includes(client._id)}
                  onChange={() => handleCheckboxChange(client._id)}
                  className="form-checkbox h-5 w-5 text-orange-600 border-gray-300 rounded accent-orange-600 transition duration-150 ease-in-out"
                />
                <h1 className="text-lg font-semibold whitespace-nowrap capitalize relative group">
                  {displayName.length >= 20 ? (
                    <>
                      {displayName.slice(0, 20) + "..."}
                      <span className="absolute left-0 top-full mt-1 p-2 bg-gray-800 text-white text-sm rounded-md hidden group-hover:block transition-opacity duration-200 z-30 whitespace-normal">
                        {displayName}
                      </span>
                    </>
                  ) : (
                    displayName
                  )}
                </h1>
              </div>
              {/* Address and Municipality Display */}

              {/* Dropdown for selecting a shipping address */}
              {client.shippingAddresses && (
                <>
                  {client.shippingAddresses?.length > 1 ? (
                    <div className="mt-1">
                      <select
                        value={
                          client.selectedAddress ||
                          selectedShippingAddresses[client._id] ||
                          ""
                        }
                        onChange={(e) =>
                          setShippingAddress(client._id, e.target.value)
                        }
                        className="border font-light w-full mt-1 rounded-lg px-2 text-sm py-1 border-gray-400"
                      >
                        <option value="" disabled>
                          Seleccionar Direccion
                        </option>
                        {client.shippingAddresses.map((address, index) => (
                          <>
                            {address.address ? (
                              <option key={index} value={address._id}>
                                {address.address}, {address.municipality} (
                                {address.type})
                              </option>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <span>
                      {client.shippingAddresses.length > 0 ? (
                        client.shippingAddresses.map((address, index) => (
                          <span>
                            {address.address ?? "Sin Direccion"},{" "}
                            {address.municipality ?? "Sin Comuna"}
                          </span>
                        ))
                      ) : (
                        <span>Sin Direcciones</span>
                      )}
                    </span>
                  )}
                </>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const ActionButtons = ({ moveClientsToAssigned, moveClientsToAvailable }) => {
  return (
    <div className="flex flex-col h-full justify-center items-center gap-4 ">
      <span onClick={moveClientsToAssigned}>
        <ArrowRightCircleIcon className="w-16 h-16 stroke-[0.5px] text-gray-500 hover:stroke-[0.8px] transition-all cursor-pointer"></ArrowRightCircleIcon>
      </span>
      <span onClick={moveClientsToAvailable}>
        <ArrowLeftCircleIcon className="w-16 h-16 stroke-[0.5px] text-gray-500 hover:stroke-[0.8px] transition-all cursor-pointer"></ArrowLeftCircleIcon>
      </span>
      <span></span>
    </div>
  );
};

export default function EditRouteClients({ routeData, closeModal }) {
  console.log({ routeData });
  const clients = useRoutes((state) => state.clients);
  const fetchRoutes = useRoutes((state) => state.fetchRoutesBeartrack);

  const [availableClients, setAvailableClients] = useState([]);
  const [assignedClients, setAssignedClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);

  const { setShippingAddress, selectedShippingAddresses } =
    useShippingAddressStore((state) => state);

  useEffect(() => {
    if (routeData) {
      setAvailableClients(
        clients.filter(
          (client) =>
            !routeData.clients.some((_client) => _client.id === client._id)
        )
      );
      const assignedClientsIds = routeData.clients.map((client) => client.id);
      console.log({ assignedClientsIds });
      setAssignedClients(
        clients.filter((client) => assignedClientsIds.includes(client._id))
      );

      for (const client of routeData.clients) {
        setShippingAddress(client.id, client.shippingAddress?.id);
      }

      console.log({ assignedClients });
    } else {
      setAvailableClients(clients);
      setAssignedClients([]);
    }
  }, [routeData, clients]);

  const submitChange = async () => {
    console.log("Asignando clientes a la ruta:", assignedClients);
    toast
      .promise(
        updateRoute(routeData.id, {
          clients: assignedClients.map((client) => ({
            id: client._id,
            selectedAddress: selectedShippingAddresses[client._id] || "",
          })),
        }),
        {
          pending: {
            render() {
              return "Guardando cambios...";
            },
            icon: "🔄",
          },
          success: {
            render() {
              return `Ruta actualizada exitosamente!`;
            },
            icon: "✅",
            autoClose: 3000, // Auto-close after 3 seconds
          },
          error: {
            render() {
              return `Hubo un error: Inténtalo de nuevo más tarde.`;
            },
            icon: "❌",
            autoClose: 5000, // Auto-close after 5 seconds
          },
        }
      )
      .then(() => {
        fetchRoutes().finally(() => {
          closeModal();
        });
      });
  };

  const handleCheckboxChange = (clientId) => {
    setSelectedClients((prevSelected) => {
      if (prevSelected.includes(clientId)) {
        return prevSelected.filter((id) => id !== clientId);
      } else {
        return [...prevSelected, clientId];
      }
    });
  };

  const moveClientsToAssigned = () => {
    const movingClients = availableClients.filter((client) =>
      selectedClients.includes(client._id)
    );
    setAssignedClients([...assignedClients, ...movingClients]);
    movingClients.forEach((client) => {
        if (!selectedShippingAddresses[client._id] && client.shippingAddresses?.length > 0) {
          // Assign the first available shipping address if none is selected
          setShippingAddress(client._id, client.shippingAddresses[0]._id);
        }
      });
    setAvailableClients(
      availableClients.filter((client) => !selectedClients.includes(client._id))
    );
    setSelectedClients([]);
  };

  const moveClientsToAvailable = () => {
    const movingClients = assignedClients.filter((client) =>
      selectedClients.includes(client._id)
    );
    setAvailableClients([...availableClients, ...movingClients]);

    setAssignedClients(
      assignedClients.filter((client) => !selectedClients.includes(client._id))
    );
    setSelectedClients([]);
  };

  if (!routeData || !clients.length) {
    console.log("Esperando datos de la ruta o lista de clientes...");
    return (
      <div className="flex items-center">
        <Spinner /> <span className="ml-2">Cargando datos de la ruta...</span>
      </div>
    );
  }

  return (
    <section>
      <h2 className="text-2xl">
        Agregar cliente a la ruta{" "}
        <span className="font-semibold">{routeData.routeName}</span>
      </h2>
      <div className="w-full flex justify-between mt-3 mx-auto h-[80vh] ">
        <ClientList
          clients={availableClients}
          title="Clientes disponibles"
          selectedClients={selectedClients}
          handleCheckboxChange={handleCheckboxChange}
        />
        <ActionButtons
          moveClientsToAssigned={moveClientsToAssigned}
          moveClientsToAvailable={moveClientsToAvailable}
        />
        <ClientList
          clients={assignedClients}
          title="Clientes en la ruta"
          selectedClients={selectedClients}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>
      <div className="flex gap-2 mt-2">
        <button
          onClick={submitChange}
          className="w-1/2 rounded-lg py-2 bg-orange-600 text-white font-semibold"
        >
          Guardar Ruta
        </button>
        <button
          onClick={closeModal}
          className="w-1/2 rounded-lg py-2 border-2 border-orange-600 text-xl font-extralight text-orange-600"
        >
          Cancelar
        </button>
      </div>
    </section>
  );
}
