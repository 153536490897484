import React, { useCallback, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Container,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { createPackage } from "../../api/packages";
import { packageStatus } from "../../utils/packageStatus";
import { posibleComs } from "../../utils/coms";
import { showToast } from "../../utils/showToast";
import SearchDropdown from "../searchDropdown";
import useAuth from "../../hooks/useAuth";
import FormDivider from "../formsDivider";
import Stepper from "../stepper";
import ErrorMessage from "../errorMessage";
import Select from 'react-select';
import PlacesAutocomplete from 'react-places-autocomplete';

export default function CreatePackage({
  edit,
  defaultData,
  closeModal,
  fetch,
  setConfirmationModal,
  setPackagesCreated,
}) {
  const [loading, setLoading] = useState(false);
  const methods = useForm({ mode: "onSubmit" });
  const { userData, email } = useAuth()
  const companyShippingAddresses = userData?.companyId?.shippingAddresses || [];

  const { watch, handleSubmit, control, formState } = methods;
  const { errors } = formState;
  const [name, phone, city, address] = watch([
    "receiver_name",
    "receiver_phone",
    "cityName",
    "street_address",
  ]);

  const isButtonDisabled =
    !name || !phone || !city || !address || loading;

  const submitValues = useCallback(
    async (values) => {
      setLoading(true);
      const selectedAddress = values.senderAddress.value;
      const parsedValues = {
        cityName: values?.cityName,
        comment: values?.comment,
        comments: "",
        delivery_preference: "Sameday",
        municipalityName: values?.cityName,
        order_id: values?.order_id,
        receiver_lastName: values?.receiver_lastName,
        receiver_name: values?.receiver_name,
        receiver_phone: values?.receiver_phone,
        senderAddress: selectedAddress.address,
        senderNeighborhood: selectedAddress.municipality,
        senderState: selectedAddress.state,
        stateName: "",
        status: 0,
        street_name: values?.street_address,
        type: "OTS",
        userMail: email,
      };

      const { data, error } = await createPackage({ values: parsedValues });

      console.log({data})

      if (data) {
        setConfirmationModal(true);
        setPackagesCreated([data]);
        setLoading(false);
        closeModal(false);
        fetch(true);
      }

      if (error) {
        setLoading(false);
        showToast(
          "Error al crear el paquete, por favor inténtelo nuevamente.",
          false
        );
      }
    },
    [email, closeModal, fetch, setConfirmationModal, setPackagesCreated]
  );


  const stepsValue = { step1: { value: defaultData?.status } };

  return (
    <Container className="mt-3">
      <Form onSubmit={handleSubmit(submitValues)}>
        {edit && (
          <Label className="forms-following-number-title">
            Pedido {defaultData.followingNumber}
          </Label>
        )}
        <FormDivider message={"Datos del emisor"} />
        <Row>
        <Col xs="12" md="12" xl="12" className="mt-3 mb-5">
            <Label className="forms-input-title">DIRECCIÓN DE RETIRO</Label>
            <Controller
              name="senderAddress"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={companyShippingAddresses.map((address) => ({
                    value: address,
                    label: `${address.address}, ${address.municipality}, ${address.state}`.toUpperCase(),
                  }))}
                  placeholder="Selecciona la dirección de retiro"
                  isDisabled={edit}
                  value={field.value}
                />
              )}
              rules={{ required: "Seleccione la dirección de retiro" }}
            />

            {errors?.senderAddress && (
              <ErrorMessage
                msg={errors?.senderAddress?.message}
              />
            )}
          </Col>
          <FormDivider message={"Datos de quién recibe"} />
          <Col xs="12" md="12" xl="12">
            <Label className="forms-input-title">NOMBRE COMPLETO</Label>
            <Controller
              name="receiver_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Escribe el nombre completo"
                  disabled={edit}
                  defaultValue={`${defaultData?.receiver_name ?? ''} ${defaultData?.receiver_lastName ?? ''}`}
                />
              )}
              rules={{ required: "Ingrese el nombre completo" }}
            />
            {errors?.receiver_name && (
              <ErrorMessage
                msg={errors?.receiver_name?.message}
              />
            )}
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">TELÉFONO</Label>
            <Controller
              name="receiver_phone"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  maxLength={9}
                  type="text"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Escribe el número de teléfono"
                  disabled={edit}
                  defaultValue={defaultData?.receiver_phone}
                />
              )}
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">NÚMERO DE VENTA</Label>
            <Controller
              name="order_id"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Escribe la referencia de tu venta"
                  disabled={edit}
                  defaultValue={defaultData?.order_id}
                />
              )}
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">COMUNA DE ENTREGA</Label>
            <Controller
              name="cityName"
              control={control}
              render={({ field }) => (
                <SearchDropdown
                  {...field}
                  options={posibleComs}
                  placeholder="Selecciona la comuna"
                  selected={
                    edit
                      ? defaultData?.shipping_address?.city?.name
                      : field.value
                  }
                  onChange={field.onChange}
                  disabled={edit}
                />
              )}
              rules={{ required: "Seleccione comuna de entrega" }}
            />
            {errors?.cityName && (
              <ErrorMessage
                msg={errors?.cityName?.message}
              />
            )}
          </Col>
          <Col xs="12" md="12" xl="12" className="mt-3">
            <Label className="forms-input-title">DIRECCIÓN DE ENTREGA</Label>
            <Controller
              name="street_address"
              control={control}
              render={({ field }) => (
                <PlacesAutocomplete
                  value={field.value || ''}
                  onChange={field.onChange}
                  onSelect={(address) => {
                    field.onChange(address);
                  }}
                  searchOptions={{
                    componentRestrictions: { country: 'cl' },
                  }}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <Input
                        {...getInputProps({
                          placeholder: 'Escribe la dirección completa',
                          className: 'p-2 px-3 forms-custom-input',
                          disabled: edit,
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Cargando...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          const style = {
                            backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                            cursor: 'pointer',
                            padding: '10px',
                          };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={suggestion.placeId}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
              rules={{ required: 'Ingrese dirección de entrega' }}
            />
            {errors?.street_address && (
              <ErrorMessage
                msg={errors?.street_address?.message}
              />
            )}
          </Col>
          <Col xs="12" md="12" xl="12" className="mt-3 mb-5">
            <Label className="forms-input-title">COMENTARIO</Label>
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="p-2 px-3 forms-custom-input-full-width"
                  placeholder="Escribe una referencia o nota"
                  disabled={edit}
                  defaultValue={defaultData?.shipping_address?.comment}
                />
              )}
            />
          </Col>
        </Row>
        {!edit && (
          <Button
            block
            color="dark"
            className="forms-custom-sender-button bg-black"
            disabled={isButtonDisabled}
          >
            Crear encomienda {loading && <Spinner size="sm" />}
          </Button>
        )}
        {edit && (
          <Row>
            <Col xs="12" md="12" xl="12">
              <Row>
                <Col xs="3" md="3" xl="3" className="d-grid">
                  <Label className="forms-input-detail-view">ESTADO</Label>
                  <Label className="forms-input-detail-view__state-data">
                    {packageStatus[defaultData?.status]}
                  </Label>
                </Col>
                <Col xs="6" md="6" xl="6" className="d-grid">
                  <Label className="forms-input-detail-view">
                    HORA ESTIMADA DE LLEGADA
                  </Label>
                  <Label className="forms-input-detail-view__time">
                    Entre: 14:00 y 21:00
                  </Label>
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="12" xl="12">
              <Row>
                <Label className="forms-input-detail-view mt-4">
                  HISTORIAL DEL PEDIDO
                </Label>
                <Stepper stepStatus={stepsValue} />
              </Row>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
}