import Axios from "../config/axios-config";
import AxiosV1 from "../config/axios-v1-config";

import { ACCESS_TOKEN, REFRESH_TOKEN } from '../config/constants'

export const Login = async (values) => {
  try {
    const login = await Axios.post("/auth/sign-in", values)
  if (!login.data.message) {
    try {
      const { accessToken, refreshToken } = login.data;
      localStorage.setItem(`${ACCESS_TOKEN}`, accessToken)
      localStorage.setItem(`${REFRESH_TOKEN}`, refreshToken)
    } catch (ex) {
      console.error(ex)
    }
  }
  Axios.defaults.headers.common["Authorization"] = `${login?.data?.accessToken}`
  return login;
  } catch (error) {
      // console.log(error)
      return { error: error.response.data };
    
  }
};

export const recoveryPassword = async (values) => {
  const login = await Axios.post("/auth/request-password-reset", values).catch((err) => {
    return { error: err.response.data };
  });
  return login;
};

export const confirmRecovery = async (values) => {
  const login = await Axios.post("/auth/reset-password", values).catch((err) => {
    return { error: err.response.data };
  });
  return login;
};

