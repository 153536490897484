export const packageStatus = {
  0: "Pendiente",
  1: "Recogido",
  2: "En bodega",
  3: "En ruta",
  4: "Entregado",
  5: "Cancelado",
  6: "Fallido",
  7: "Fallido",
  30: "En ruta",
};

export const packageStatusStyles = {
  0: "pending-style",
  1: "picked-style",
  2: "in-storage-style",
  3: "in-route-style",
  4: "delivered-style",
  5: "cancelled-style",
  6: "cancelled-style",
  7: "cancelled-style",
  30: "in-route-style",
};

export const packageStatusEvidenceStyles = {
  0: "pending-style-evidence",
  1: "picked-style-evidence",
  2: "in-storage-style-evidence",
  3: "in-route-style-evidence",
  4: "delivered-style-evidence",
  5: "cancelled-style-evidence",
  6: "cancelled-style-evidence",
  7: "cancelled-style-evidence",
  30: "in-route-style-evidence",
};
