import { useCallback, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Row,
  Col,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useForm, Controller, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import useRoutes from "../../store/routes";
import { getDeliveryUsers } from '../../api/user';
import { addRouteApi, updateRoute } from "../../api/routes";
import { ChevronDown, UserIcon } from "lucide-react";
import useAuth from "../../hooks/useAuth";

const Select = ({ options, value, onChange, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (option) => {
    onChange(option);  // Call the onChange prop with the selected option
    setIsOpen(false);
    setSearchTerm('');
  };

  return (
    <div className="relative w-full">
      <button
        onClick={e => {
          e.preventDefault()
          setIsOpen(!isOpen)
        }}
        className="w-full bg-white border border-gray-300 rounded-md shadow-sm px-2 py-2 text-left cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
      >
        <div className="flex gap-2">
          <UserIcon></UserIcon>{value ? value.label : placeholder}
        </div>
        <span className="absolute inset-y-0 right-3 flex items-center pr-2 pointer-events-none">
          <ChevronDown className="h-5 w-5 text-gray-400" />
        </span>
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-[95%] bg-white shadow-lg rounded-md max-h-60 overflow-auto focus:outline-none">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 border-b border-gray-300 focus:outline-none"
            placeholder="Buscar..."
          />
          <ul className="py-1 text-gray-700">
            {filteredOptions.length > 0 ? (
              filteredOptions.map(option => (
                <li
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  className="cursor-pointer hover:bg-indigo-600 hover:text-white px-4 py-2"
                >
                  {option.label}
                </li>
              ))
            ) : (
              <li className="px-4 py-2 text-gray-500">No hay repartidores</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default function CreateRouteBeartrack({
  edit,
  closeModal,
  route,
}) {
  const deliveries = useRoutes(state => state.deliveries)
  const [isLoadingDelivery, setIsLoadingDelivery] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchRoutes = useRoutes((state) => state.fetchRoutesBeartrack);

  const [dayType, setDayType] = useState("weekday");

  const methods = useForm({
    mode: "onSubmit",
    defaultValues: edit
      ? route
      : {
          frequency: "weekday",
        },
  });

  useEffect(() => {
    if (edit && deliveries.length > 0) {
      const frequencyValue =
        route.frequency === "Lunes a viernes" ? "weekday" : "saturday";

      const deliveryData = deliveries.find(
        (d) => d._id === route.deliveryMan.id
      );
      if (!deliveryData) return;

      methods.reset({
        routeName: route.routeName,
        deliveryMan: {
          value: deliveryData._id,
          label: `${deliveryData.name} ${deliveryData.lastName}`,
        },
        frequency: frequencyValue,
      });
      setDayType(frequencyValue);
    }
  }, [edit, route, methods, deliveries]);

  const submitValues = useCallback(
    async (values) => {
      setLoading(true);

      const { deliveryMan, routeName = "", ...otherValues } = values;

      const updatedValues = {
        ...otherValues,
        deliveryMan: deliveryMan.value,
        routeName:
          routeName.length === 0
            ? constructRouteName(values)
            : routeName,
      };

      const toastPromise = edit
        ? updateRoute(route.id, updatedValues)
        : addRouteApi(updatedValues);

      try {
        await toast.promise(
          toastPromise,
          {
            pending: "Guardando cambios...",
            success: edit
              ? "Ruta actualizada exitosamente"
              : "Ruta creada exitosamente",
            error: {
              render({ data }) {
                return `Error al guardar los cambios: ${data.message}`;
              },
            },
          },
          {
            position: "bottom-center",
            autoClose: 2000,
          }
        );
      } catch (error) {
        console.log(error);
      } finally {
        fetchRoutes().then(() => {
          setLoading(false);
          closeModal(false);
        });
      }
    },
    [closeModal, edit, dayType, fetchRoutes, route]
  );

  const isFormComplete = methods.watch("deliveryMan");

  const constructRouteName = useCallback(
    (values) => {
      const routeNumber = `Ruta ${Math.floor(Math.random() * 1000)}`;
      const dayTypeText = dayType === "weekday" ? "Entre semana" : "Sábado";
      const delivererName = values.deliveryMan?.label || "Sin repartidor";

      return `${routeNumber} - ${dayTypeText} - ${delivererName}`;
    },
    [dayType]
  );

  const handleDayTypeChange = (event) => {
    const value = event.target.value;
    setDayType(value);
    methods.setValue("frequency", value);
  };

  if(!deliveries) return <>Loading</>

  return (
    <div>
      <h1 className="text-black font-semibold text-xl mb-0 mt-0">
        {edit ? "Editar ruta" : "Crear ruta"}
      </h1>
      <Container className="mt-2">
        <Form onSubmit={methods.handleSubmit(submitValues)}>
          <Row className="mt-4">
            <Col xs="12">
              <Controller
                name="routeName"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    className="p-2 px-3 forms-custom-input"
                    placeholder="Nombre de la ruta"
                  />
                )}
              />
            </Col>
            <Col xs="12" md="12" xl="12" className="my-4">
              <Label className="font-semibold">
                Elige la frecuencia de la ruta
              </Label>
              <Row>
                <Col>
                  <FormGroup check className="custom-radio">
                    <Input
                      type="radio"
                      value="weekday"
                      checked={dayType === "weekday"}
                      onChange={handleDayTypeChange}
                      className="custom-control-input"
                    />
                    <Label check className="custom-control-label">
                      Lunes a viernes
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check className="custom-radio">
                    <Input
                      type="radio"
                      value="saturday"
                      checked={dayType === "saturday"}
                      onChange={handleDayTypeChange}
                      className="custom-control-input"
                    />
                    <Label check className="custom-control-label">
                      Sábados
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Select
              value={methods.getValues("deliveryMan")}
              placeholder={
                isLoadingDelivery ? "Cargando repartidores..." : "Repartidor"
              }
              onChange={(option) => {
                methods.setValue("deliveryMan", option);
              }}
              options={deliveries.map((delivery) => ({
                value: delivery._id,
                label: `${delivery.name} ${delivery.lastName}`,
              }))}
            />
          </Row>

          <Row className="mt-4">
            <Col xs="6">
              <Button
                color="orange"
                className={`w-100 bg-[#F8612C] text-white hover:bg-orange-500 ${
                  !isFormComplete ? "disabled-button" : ""
                }`}
                type="submit"
                disabled={!isFormComplete}
              >
                Guardar ruta
              </Button>
            </Col>
            <Col xs="6">
              <Button
                color="white"
                className="w-100 text-black border-black"
                type="button"
                onClick={() => closeModal(false)}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}
