import React, { useState } from "react";
import { DropdownItem, Tooltip } from "reactstrap";

export default function CustomDropdownItem({
  value,
  label,
  callback,
  setSearch,
  tooltip,
}) {
  //console.log({value})
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <DropdownItem
      onClick={() => {
        setSearch("");
        callback(value);
      }}
      className="w-90 d-flex truncate-text"
      id={"Tolltip-" + value}
    >
      <span className="w-100 truncate-text">{label}</span>
      {tooltip && (
        <Tooltip
          isOpen={tooltipOpen}
          target={"Tolltip-" + value}
          toggle={toggle}
        >
          {label}
        </Tooltip>
      )}
    </DropdownItem>
  );
}
