import Axios from "../config/axios-v1-config";

const API_URL = `${process.env.REACT_APP_API_CONNECTION}`;

export const createVehicle = async (data) => {
    try {
      const response = await Axios.post(`${API_URL}/vehicles`, data);
      return response.data;

    } catch (error) {
      console.error('Error al crear el vehículo:', error);
      throw error;
    }
  };


  export const updateVehicle = async (id, data) => {
    try {
      const response = await Axios.put(`${API_URL}/vehicles/${id}`, data);
      return response.data;
      
    } catch (error) {
      console.error('Error al actualizar el vehículo:', error);
      throw error;
    }
  };

