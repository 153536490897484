import React from "react";
import { Loader } from "@googlemaps/js-api-loader";

export default function Map({ coords, id, className }) {
  //console.log(coords)
  //console.log(id)

  const loader = new Loader({
    apiKey: "AIzaSyCg6QBDE2eqn0TKdpKhTnpb3-f6UTKqzxs",
    version: "weekly",
    libraries: ["places"],
  });

  const mapOptions = {
    center: {
      lat: coords?.latitude || 0,
      lng: coords?.longitude || 0,
    },
    zoom: 14,
  };

  //console.log(mapOptions)

  loader
    .load()
    .then(async (google) => {
      const map = new google.maps.Map(
        document.getElementById(id),
        mapOptions
      );
      new google.maps.Marker({
        position: mapOptions.center,
        map: map,
      });
    })
    .catch((e) => {
      //console.log(e);
      // do something
    });

  return <div id={id} className={`mt-3 ${className}`}/>;
}
