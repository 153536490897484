import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import useRoutes from "../../../store/routes";
import Pagination from "../../../components/pagination";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Modal from "../../../components/modal";
import CreateRouteBeartrack from "../../../components/createRouteBeartrack/createRouteBeartrack";
import BlockDeleteUserBeartrack from "../../../components/blockDeleteUserBeartrack";
import { Edit, Trash, Route, FilterIcon } from "lucide-react";
import EditRouteClients from "../../../components/editRouteClients";
import { useNavigate } from "react-router-dom";
import { iconList } from "../../../utils/iconList";
import useAuth from "../../../hooks/useAuth";
import { getActiveClientsApi } from "../../../api/company";
import { getDeliveryUsers } from "../../../api/user";
import { getRoutesWithDetails } from "../../../api/routes";

const AdminWithdrawalsBreadcrumb = () => {
  const navigate = useNavigate();

  return (
    <Breadcrumb className="custom-breadcrumb-container">
      <BreadcrumbItem
        className="d-flex align-items-center cursor-pointer"
        onClick={() => navigate("/admin")}
      >
        <img src={iconList.home} alt="home" className="mr-2" />
        <span>Inicio</span>
      </BreadcrumbItem>
      <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
        <span>Retiros</span>
      </BreadcrumbItem>
      <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
        <span>Retiros realizados</span>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default function AdminWithdrawalsView() {
  const { user, isLoading } = useAuth();

  console.log({ user, isLoading });

  const userDeliveryRole = "delivery";
  const status = "active";
  
  const fetchRoutes = useRoutes((state) => state.fetchRoutesBeartrack);

  const fetchClients = useRoutes((state) => state.fetchClients);
  const fetchDeliveries = useRoutes((state) => state.fetchDeliveries);

  const routes = useRoutes((state) => state.routes); 

  const [query, setQuery] = useState("");

  const [loading, setLoading] = useState(false);
  const pageSize = 7;
  //modal
  const [openModalRoute, setOpenModalRoute] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEditClients, setOpenModalEditClients] = useState(false);
  //paginator
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const logisticCompanyId = user?.logisticCompany._id || null;
    if (logisticCompanyId) {
      setLoading(true);
      Promise.all([
        fetchClients(logisticCompanyId),
        fetchDeliveries(status, userDeliveryRole, logisticCompanyId),
        fetchRoutes(),
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [user]);

  const handleCreateRoute = () => {
    setOpenModalRoute(true);
  };

  const handleEditRoute = (route) => {
    setSelectedRoute(route);
    setOpenModalEdit(true);
  };

  const handleDeleteRoute = (route) => {
    setSelectedRoute(route);
    setOpenModalDelete(true);
  };

  const handleEditRouteClients = (route) => {
    setSelectedRoute(route);
    console.log({route})
    setOpenModalEditClients(true);
  };

  const [filterState, setFilterState] = useState({
    week: true,
    weekend: true,
  });

  if (isLoading || !routes) {
    return <>Loading</>;
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFilterState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const filtros = [
    {
      name: "week",
      description: "Lunes a viernes",
    },
    {
      name: "weekend",
      description: "Sábados",
    },
  ];

  //const filteredRoutes = applyFilters(routes); //- debe recibir array de rutas

  const columns = [
    {
      field: "routeName",
      headerName: "Nombre ruta",
      headerClassName: "dataGridHeader",
      width: 250,
    },
    {
      field: "frequency",
      headerName: "Frecuencia",
      headerClassName: "dataGridHeader",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <span className="rounded-full px-2 py-[6px] border-2 border-orange-300 bg-orange-100 text-orange-900">
            {params.value}
          </span>
        </div>
      ),
    },
    {
      field: "clients",
      headerName: "Cantidad de clientes",
      headerClassName: "dataGridHeader",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <span className="text-xl font-light">{params.value.length}</span>
      ),
    },
    {
      field: "deliveryMan",
      headerName: "Repartidor asignado",
      headerClassName: "dataGridHeader",
      width: 200,
      renderCell: (params) => (
        <span className="relative -top-2">
          <span className="font-semibold">{params.value.name}</span>
          <br />
          <span className="absolute -top-2">{params.value.email}</span>
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Acciones",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      align: "center",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center justify-center h-full space-x-4">
          <span
            onClick={() => handleEditRouteClients(params.row)}
            className="flex items-center cursor-pointer"
          >
            <Route className="h-5 w-5 hover:scale-125 hover:text-orange-500 transition-all" />
          </span>
          <span
            onClick={() => handleEditRoute(params.row)}
            className="flex items-center cursor-pointer"
          >
            <Edit className="h-5 w-5 hover:scale-125 hover:text-orange-500 transition-all" />
          </span>
          <span
            onClick={() => handleDeleteRoute(params.row)}
            className="flex items-center cursor-pointer"
          >
            <Trash className="h-5 w-5 hover:scale-125 hover:text-orange-500 transition-all" />
          </span>
        </div>
      ),
    },
  ];

  console.log({ routes });

  const filteredRoutes = routes
    .filter((route) => {
      // Si no hay filtros activos, no mostrar ningún registro
      if (!filterState.week && !filterState.weekend) {
        return false;
      }

      // Aplicar filtros según el estado
      if (filterState.week && route.frequency === "Lunes a viernes") {
        return true;
      }
      if (filterState.weekend && route.frequency === "Sábado") {
        return true;
      }
      return false;
    })
    .filter((route) => {
      if (query.length > 0) {
        return `${route.deliveryManName} ${route.deliveryManEmail} ${route.routeName}`
          .toLowerCase()
          .includes(query.toLowerCase());
      } else {
        return true;
      }
    });

  const rows = filteredRoutes
    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
    .map((route) => {
      const {
        id,
        idDeliveryMan,
        routeName,
        deliveryManName,
        deliveryManEmail,
        frequency,
        count,
        clients
      } = route;

      return {
        id,
        clients,
        routeName, // Nombre de la ruta
        deliveryMan: {
          id: idDeliveryMan,
          email: deliveryManEmail,
          name: deliveryManName,
        }, // Nombre completo del repartidor
        frequency, // Frecuencia
        count, // Cantidad de clientes
      };
    });

  return (
    <section className="w-full">
      <div className="ml-2">
        <AdminWithdrawalsBreadcrumb />
        <h1 className="text-2xl font-semibold">Administrar Retiros</h1>
      </div>
      <div className="w-full m-1 mt-4 pb-2 border rounded-xl border-gray-600 ">
        <Modal
          size={"md"}
          open={openModalRoute}
          setOpen={() => setOpenModalRoute()}
          body={
            <CreateRouteBeartrack
              closeModal={setOpenModalRoute}
              route={selectedRoute}
              edit={false}
            />
          }
        ></Modal>
        <Modal
          size={"md"}
          open={openModalEdit}
          setOpen={() => setOpenModalEdit()}
          body={
            <CreateRouteBeartrack
              closeModal={setOpenModalEdit}
              route={selectedRoute}
              edit={true}
            />
          }
        ></Modal>
        <Modal
          size={"md"}
          open={openModalDelete}
          setOpen={() => setOpenModalDelete()}
          body={
            <BlockDeleteUserBeartrack
              closeModal={setOpenModalDelete}
              fetch={() => {}}
              route={selectedRoute}
              edit={true}
              isRoute={true}
            />
          }
        ></Modal>

        <Modal
          size={"lg"}
          open={openModalEditClients}
          setOpen={() => setOpenModalEditClients()}
          body={
            selectedRoute && (
              <EditRouteClients
                closeModal={() => {
                  setOpenModalEditClients(false);
                }}
                routeData={selectedRoute}
              />
            )
          }
        ></Modal>

        <Row className="mb-3 mt-4 ml-2 mr-2">
          <Col xs={6}>
            <div>
              <h1 className="text-xl font-bold flex items-center">
                Rutas creadas
                <div className="text-xs ml-2 text-orange-800 bg-orange-200 border-2 border-orange-300 rounded-full px-2 py-1">
                  {routes.length} rutas creadas
                </div>
              </h1>
              <p className="mt-2 text-sm">Gestiona las rutas de retiro.</p>
            </div>
          </Col>
          <Col xs={6} className="d-flex align-items-center justify-content-end">
            <UncontrolledDropdown>
              <DropdownToggle nav>
                <Button
                  color="white"
                  className="w-90 bg-[#FFF] border border:bg[#CCCCCC] text-black hover:bg-[#CCCCCC] d-flex align-items-center"
                >
                  <FilterIcon className="w-4 h-4"></FilterIcon>
                  Filtrar
                  <span className="px-2 ml-2 border-2 rounded-full border-orange-300 text-orange-900 bg-orange-200">
                    {filtros.length}
                  </span>
                </Button>
              </DropdownToggle>

              <DropdownMenu
                end
                style={{
                  marginTop: "0.5rem",
                  width: "250px",
                  minWidth: "100px",
                  marginLeft: "0px",
                }}
              >
                <DropdownItem toggle={false}>
                  <Input
                    className="mr-2"
                    type="checkbox"
                    name="week"
                    checked={filterState.week}
                    onChange={handleCheckboxChange}
                  />
                  Lunes a viernes
                </DropdownItem>
                <DropdownItem toggle={false}>
                  <Input
                    className="mr-2"
                    type="checkbox"
                    name="weekend"
                    checked={filterState.weekend}
                    onChange={handleCheckboxChange}
                  />
                  Sábados
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <div className="ml-2 me-2">
              <input
                type="text"
                placeholder="Buscar ruta..."
                className="form-control"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <Button
              color="orange"
              className=" bg-[#F8612C] whitespace-nowrap text-white hover:bg-orange-500 d-flex align-items-center"
              onClick={() => handleCreateRoute()}
            >
              Crear nueva ruta
              <span
                className="material-symbols-outlined ms-1"
                style={{ fontSize: "20px" }}
              >
                route
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="mx-auto overflow-y-auto md:h-[50vh] xl:h-[65vh]">
          {loading ? (
            <div className="flex justify-center items-center w-full h-[75vh]">
              <Spinner />
            </div>
          ) : (
            <DataGrid
              sx={{
                shadow: 2,
                marginRight: 0,
                paddingRight: 0,
                marginLeft: 0,
                paddingLeft: 0,
                borderRadius: 0,
                border: "none",
                headerClassName: "black",
                headerAlign: "center",
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#CCCCCC",
                  color: "black",
                },
                // "& .MuiDataGrid-cell:hover": {
                //   color: "warning.dark", //es naranjo
                // },
              }}
              loading={loading}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: pageSize,
                  },
                },
              }}
              hideFooter
              hideFooterRowCount
              hideFooterSelectedRowCount
              hideFooterPagination
              disableColumnFilter
              disableColumnSorting
              pageSizeOptions={[7]}
              rowsPerPageOptions={[7]}
              autoHeight
              getRowHeight={() => 70} // Ajusta altura de cada fila
              localeText={{
                noRowsLabel: "No hay rutas con los filtros seleccionados",
              }}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          )}
        </Row>

        <div className="w-100 d-flex justify-content-center">
          {routes.length !== 0 ? (
            <>
              <span className="w-50 table-counter">
                Mostrando {pageSize * currentPage - pageSize} -{" "}
                {pageSize * currentPage > routes.length
                  ? routes.length
                  : pageSize * currentPage}{" "}
                de {routes.length} rutas
              </span>
              <Pagination
                documents={routes.length || 1}
                pagination={pageSize || 0}
                page={(newPage) => setCurrentPage(newPage + 1)}
                pageSelected={currentPage - 1}
              />
            </>
          ) : (
            <>Cargando</>
          )}
        </div>
      </div>
    </section>
  );
}
